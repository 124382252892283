import VehiculeForm from "./form/VehiculeForm"

const NouveauVehiculePage = ({}) => {

  return (
    
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Nouveau Véhicule</h3>
          <VehiculeForm vehicule={{dateachat: new Date(), visite: new Date(), assurance: new Date()}} />
        </div>
      </div>
    </div>
  )
}

export default NouveauVehiculePage