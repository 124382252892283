import { Field } from "formik"
import { Fragment } from "react"

const DisponibiliteFields = () => {

  return (
  <Fragment>
  <div className="row">
    <div className="col-lg-5">
      <div className="form-group">
        <label>Disponibilité</label>
        <Field className="form-control form-control-sm" as="select" name="disponibilite">
          <option value="ND">Choisir disponibilité</option>
          <option>Disponible</option>
          <option>Non disponible</option>
        </Field>
      </div>
    </div>
    <div className="col-lg-7">    
      <div className="form-group">
        <label>Période de disponibilité</label>
        <Field className="form-control form-control-sm" as="input" type="text" name="periodeDispo" />
      </div>
    </div>
  </div>
</Fragment>)
}

export default DisponibiliteFields