import PropTypes from 'prop-types'
import './quantite.css'

const QuantiteWidget = ({handleIncrease, value, className}) =>{

  return (
    <div className='quantite-widget'>
      <button type='button' className="btn-action" onClick={e => handleIncrease(--value)}>-</button>
      <span className={`quantite-value ${className}`}>{value}</span>
      <button type='button' className="btn-action" onClick={e => handleIncrease(++value)}>+</button>
    </div>
  )
}

QuantiteWidget.propTypes = {
  handleIncrease: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
}

export default QuantiteWidget