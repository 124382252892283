

const NombreToLettre = ({nombre, suffixe}) => {
  return (<p>{getLetter(nombre).toLocaleUpperCase()} {suffixe}</p>)
}

NombreToLettre.defautPros = {
  nombre: 0,
  suffixe: "F CFA"
}

export default NombreToLettre

function getLetter($number){
  return millier($number)
}

function millier($valeur){
  let $resultat = "";
  let $nbreFois = 0;
  let $reste = 0;

  //Milliard ou nombre entre dix (10) et douze (12) chiffres
  $nbreFois = Math.floor($valeur / 1000000000);
  $reste = $valeur % 1000000000;

  if($nbreFois == 1){
    $resultat += "un milliard ";
  } else if ($nbreFois > 1) {
    $resultat += centaine($nbreFois) + "milliard ";
  }
  $valeur = $reste;

  //Millions ou nombre entre sept (07) et neuf (09) chiffres
  $nbreFois = Math.floor($valeur / 1000000);
  $reste = $valeur % 1000000;

  if($nbreFois == 1){
    $resultat += "un million ";
  }else if ($nbreFois > 1) {
    $resultat += centaine($nbreFois) + "millions ";
  }

  $valeur = $reste;

  //Millier ou nombre entre quatre (04) et six (06) chiffres
  $nbreFois = Math.floor($valeur/1000);
  $reste = $valeur % 1000;

  if( $nbreFois == 1){
      $resultat += "mille ";
  }else if($nbreFois > 1 ) {
      $resultat += centaine($nbreFois) + "mille " + centaine($reste);
  }else{
      $resultat += centaine($reste);
  }

  return $resultat;
}

function centaine($valeur){
  let $resultat = "";
  let $reste = 0;
  let $nbFois = "";

  //centaine ou nombre à trois (03) chiffres
  $nbFois = Math.floor($valeur / 100);
  $reste = $valeur % 100;

  if ($nbFois === 1){
    $resultat += "cent ";
  }else if(1 < $nbFois) {
    $resultat += dizaine($nbFois) + "cent ";
  }

  $resultat += compose($reste);

  return $resultat;
}

function compose($valeur) {
  let $resultat = "";

  //dizaine ou nombre à deux (02) chiffres
  let $nbFois = Math.floor($valeur / 10);
  let $reste = $valeur % 10;

  if (($nbFois <= 2) && ($reste === 0)){
    $resultat += dizaine($valeur);
  }else{
    if ((($nbFois + 18) === 27) || (($nbFois + 18) === 25))
    {
      $resultat += dizaine($nbFois + 18) + " " + dizaine($reste + 10);
    }else{
      if($nbFois === 0) {
        $resultat += dizaine($reste);
      }else if ($nbFois == 1){
        $resultat += dizaine($reste+10);
      }else{
        if((20 <= ($nbFois + 18)) && $reste == 1) {
          $resultat += dizaine($nbFois + 18) + " et " + dizaine($reste);
        }else{
          $resultat += dizaine($nbFois + 18) + dizaine($reste);
        }
      }
    }
  }

  return $resultat;
}

function dizaine($valeur){
  let $resultat = "";

  switch (parseInt($valeur)){
      case  1: $resultat = "un "            ; break;
      case  2: $resultat = "deux "          ; break;
      case  3: $resultat = "trois "         ; break;
      case  4: $resultat = "quatre "        ; break;
      case  5: $resultat = "cinq "          ; break;
      case  6: $resultat = "six "           ; break;
      case  7: $resultat = "sept "          ; break;
      case  8: $resultat = "huit "          ; break;
      case  9: $resultat = "neuf "          ; break;
      case 10: $resultat = "dix "           ; break;
      case 11: $resultat = "onze "          ; break;
      case 12: $resultat = "douze "         ; break;
      case 13: $resultat = "treize "        ; break;
      case 14: $resultat = "quatorze "      ; break;
      case 15: $resultat = "quinze "        ; break;
      case 16: $resultat = "seize "         ; break;
      case 17: $resultat = "dix-sept "      ; break;
      case 18: $resultat = "dix-huit "      ; break;
      case 19: $resultat = "dix-neuf "      ; break;
      case 20: $resultat = "vingt "         ; break;
      case 21: $resultat = "trente "        ; break;
      case 22: $resultat = "quarante "      ; break;
      case 23: $resultat = "cinquante "     ; break;
      case 24: $resultat = "soixante "      ; break;
      case 25: $resultat = "soixante "      ; break;
      case 26: $resultat = "quatre-vingt "  ; break;
      case 27: $resultat = "quatre-vingt "  ; break;
      default: $resultat = ""               ; break;
  }
  return $resultat;
}