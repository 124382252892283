import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom'
import store, { history } from './reducer/config/configureStore'
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient()

root.render(
  <Provider store={store}>   
    <React.StrictMode>
      <Router history={history}>
        <QueryClientProvider client={queryClient} >
          <App />
        </QueryClientProvider>
      </Router>        
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
