import WebClient from "../../utils/WebClient"

export function getListeClient(page, search){
  return WebClient.get(`/partenaires/clients?page=${page}&search=${search}`)
}

export function getPartenaireByIdWithOrders(id){
  return WebClient.get(`/partenaires/factures/${id}/details`)
}

export function getPartenaireById(id){
  return WebClient.get(`/partenaires/${id}/details`)
}