import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { Fragment, useState } from 'react'
import { useQuery } from "react-query"
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import NoInternet from '../../component/NoInternet'
import Pagination from '../../component/Pagination'
import SkeletonLoader from '../../component/SkeletonLoader'
import { NOTIFICATION_ERROR } from '../../reducer/notificationRx'
import IasMenu from '../../routes/web'
import {getListeProduit} from './actions'

const ListeProduitPage = () => {
  
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const { isLoading, isError, data } = useQuery(['produits',page], ()=> getListeProduit(page), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération des produits'})}
  })

  if(isError){
    return <NoInternet />
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Stock de pièces</h3>
          <hr/>
          <div className="table-responsive">
            <table className="table table-hover table-sm expandable-table">
              <thead>
                <tr className="table-info">
                  <th>Référence</th>
                  <th>Famille</th>
                  <th>Libellé</th>
                  <th>Stock</th>
                  <th>Prix Unitaire</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? 
                <tr><td colSpan={5}><SkeletonLoader /></td></tr>
                : 
                <Fragment>{data.data.data.map((item, k) => <ProductLine item={item} key={k} />)}</Fragment> }
              </tbody>
            </table>
            {data && <Pagination handleSelectPage={setPage} items={data.data.links} />}
          </div>
        </div>
      </div>
    </div>
  )
}
const ProductLine = ({item}) => {
  
  const navigate = useNavigate()

  return <tr onClick={() => {navigate(IasMenu.stock.details.replace(':reference',item.reference))}}>
          <td style={{lineHeight: "0"}}> {item.reference} </td>
          <td>{item.famille.libelle}</td>
          <td>{item.libelle}</td>
          <td>{item.stock}</td>
          <td className="text-right">{Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(item.prixunitaire)}</td>
        </tr>
}

export default ListeProduitPage