import DatePicker from 'react-date-picker'
import './date.css'
import PropTypes from 'prop-types'

const DateInput = ({ field, form, changeTrigger, ...props }) => {  
  const handleChange = (value) => {
    changeTrigger?.(value)
    form.setFieldValue(field.name, value)
  }
  return (<DatePicker id={field.name} className="form-control form-control-sm" {...field} {...form} format="dd/MM/yyyy" onChange={handleChange} />)
}

DateInput.propTypes = {
  handleChange : PropTypes.func
}
export default DateInput