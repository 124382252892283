import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { useState } from "react"
import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom";
import DropdownButton from "../../../component/DropdownButton";
import PrefixNumeroFacture from "../../../component/Form/Facture/prefixNumeroFacture";
import NombreToLettre from "../../../component/Helper/NombreToLettre";
import Loading from "../../../component/Loading";
import StatutDescription, { PIECE_COMPTABLE_PRO_FORMA } from "../../../component/StatutDescription";
import { NOTIFICATION_ERROR } from "../../../reducer/notificationRx";
import { getListeFactureDetails } from "../action";

const DetailsFacturePage = () => {
  
  let params = useParams();
  const dispatch = useDispatch()
  
  const { isLoading, isError, data } = useQuery(['factures','details',params.reference], ()=> getListeFactureDetails(params.reference), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération du détail de la facture'})}
  })

  if(isLoading) {return <div>Chargement ... <Loading /></div>}

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card px-2">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <DropdownButton 
                  className="btn btn-primary"
                  title="Actions" 
                  menus={[
                    {item: <Link to="button" className="dropdown-item">Modifier</Link>, isSepareted: false},
                    {item: <Link to="button" className="dropdown-item">Créer à partir de ...</Link>, isSepareted: false},
                    {item: <Link to="button" className="dropdown-item">Facture</Link>, isSepareted: false},
                    {item: <Link to="button" className="dropdown-item">Bon de livraison</Link>, isSepareted: true},
                  ]}
                  />
              </div>
              <div className="col-lg-6">
                <h3 className="text-right my-5"><StatutDescription statut={data.data.etat} /> #{data.data.referenceproforma} {data.data.etat !== PIECE_COMPTABLE_PRO_FORMA && `| #${data.data.referencefacture}`}</h3>
              </div>
            </div>
            <hr />
          </div>
          <div className="container-fluid d-flex justify-content-between">
            <div className="col-lg-3 pl-0">
              <p className="mt-5 mb-2">Référence : <b>#<PrefixNumeroFacture reference={data.data.referenceproforma} /></b></p>
              <p>Date : <b>{format(parseISO(data.data.creationproforma), "dd/MM/yyyy HH:mm:ss")}</b></p>
            </div>
            <div className="col-lg-3 pr-0">
              <p className="mt-5 mb-2 text-right"><b>{data.data.partenaire.raisonsociale}</b></p>
              <p className="text-right">{data.data.partenaire.telephone},<br /> CC : {data.data.partenaire.comptecontribuable}.</p>
            </div>
          </div>
          <div className="container-fluid mt-5 d-flex justify-content-center w-100">
            <div className="table-responsive w-100">
              <table className="table">
                <thead>
                  <tr className="bg-dark text-white">
                      <th>#</th>
                      <th>Référence</th>
                      <th>Description</th>
                      <th className="text-right">P.U.</th>
                      <th className="text-center">Quantité</th>
                      <th className="text-right">Total</th>
                    </tr>
                </thead>
                <tbody>
                  {data.data.lignes.map((item, k) => {
                    return (
                      <tr className="text-right">
                        <td className="text-left">{k+1}</td>
                        <td className="text-left">{item.reference}</td>
                        <td className="text-left" style={{wordBreak: "break-word"}}>{item.designation}</td>
                        <td>{item.prixunitaire}</td>
                        <td className="text-center">{item.quantite}</td>
                        <td>{item.quantite * item.prixunitaire}</td>
                      </tr>
                    )}
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <hr/>
          <div className="container-fluid mt-5 w-100">
            <div className="row">
              <div className="col-lg-6">
                <h4>Option de paiement</h4>
                <ul className="list-ticked">
                  <li>Condition de paiement : <b>{data.data.conditions}</b></li>
                  <li>Validité de l'offre &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>{data.data.validite}</b></li>
                  <li>Délai de paiement &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>{data.data.delailivraison}</b></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <p className="text-right mb-2">Montant HT : {Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(data.data.montantht)}</p>
                <p className="text-right">TVA (18%) {data.data.isexonere ? "(Exonéré de TVA)" : ""} : {Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(data.data.montantht * data.data.tva)}</p>
                <h4 className="text-right mb-5">Montant TTC :  {Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(data.data.montantht * (data.data.isexonere ? 1 : (1 + data.data.tva)))}</h4>
              </div>
            </div>
            <hr />
            <b><NombreToLettre nombre={data.data.montantht * (data.data.isexonere ? 1 : (1 + data.data.tva))} suffixe="FRANCS CFA" /></b>
          </div>
          <div className="container-fluid w-100">
            <button className="btn btn-primary float-right mt-4 ml-2"><i className="ti-printer mr-1"></i>Imprimer</button>
            <button className="btn btn-success float-right mt-4"><i className="ti-export mr-1"></i>Envoyer</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsFacturePage