import TopBar from './layout/TopBar';
import SideBar from './layout/SideBar';
import PageRouter from './layout/PageRouter';
import React, { Fragment } from 'react';
import Notification from './component/Notification';

function AppWrapper(){
  return(    
    <Fragment>
      <TopBar />
      <div className="container-fluid page-body-wrapper">
        <SideBar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <Notification />
              <PageRouter />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AppWrapper