import WebClient from "../../../../utils/WebClient";

export function searchPartner(search) {
  return WebClient.get(`/partenaires/search?raisonsociale=${search}`).then(response => {
    return response.data.data.map(item => {
      return {
        label: item.raisonsociale,
        value: item
      }
    })
  })
}