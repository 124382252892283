import PropTypes from 'prop-types';
import IasIcons from "./icons/IasIcons";

export const LineInfo = ({ libelle, value, icons, iconColor, iconSize }) => {

  return (
    <p className="clearfix">
      <span className="float-left text-muted">{libelle}</span>
      <span className="float-right">{icons !== undefined && <IasIcons className={icons} color={iconColor} size={iconSize} />} {value}</span>
    </p>
  );
};
LineInfo.defaultPros = {
  icons: undefined,
  iconColor: "green",
  iconSize: "10px",
};
LineInfo.propTypes = {
  libelle: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icons: PropTypes.string,
};
