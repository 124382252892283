import { Fragment } from "react"
import PropTypes from 'prop-types'
import QuantiteWidget from "../../../component/QuantiteWidget"
import { FACTURE_LOCATION } from "../action"

const FactureTabView = ({mode, lines, handleUpdateQuantity, handleUpdatePeriode, handleRemoveItem}) => {

  return <Fragment>
    <div className="table-responsive w-100">
      <table className="table">
        <thead>
          <tr className="bg-dark text-white">
            <td width="3%">#</td>
            <td width="10%">Référence</td>
            <td>Description</td>
            <td className="text-center">Quantité</td>
            {mode === FACTURE_LOCATION && <td className="text-center">Période</td>}
            <td className="text-right">P.U</td>
            <td className="text-right">Total</td>
          </tr>
        </thead>
        <tbody>
          {lines.map((line, k) => <tr key={k}>
            <td>#{k+1}</td>
            <td>{line.reference}</td>
            <td>{line.description}</td>
            <td className="text-center">
              <QuantiteWidget 
                handleIncrease={(qte)=> handleUpdateQuantity(k, qte)} 
                value={line.quantite} 
                />
            </td>
            {mode === FACTURE_LOCATION && 
            <td className="text-center">
              <QuantiteWidget 
                handleIncrease={(qte)=> handleUpdatePeriode(k, qte)} 
                value={line.periode} 
                /> {line.unite}
            </td>}
            <td className="text-right">{line.pu}</td>
            <td className="text-right">{line.pu * line.quantite * (line.periode ?? 1)}</td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </Fragment>
}

FactureTabView.defaultPros = {
  lines: [],
  handleUpdatePeriode: (a,qte)=>{alert(a, qte)}
}

FactureTabView.propTypes = {
  lines: PropTypes.array.isRequired,
  handleUpdateQuantity: PropTypes.func.isRequired,
  handleUpdatePeriode: PropTypes.func.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
}

export default FactureTabView