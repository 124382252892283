import AsyncSelect from 'react-select/async';
import { getAllTypeIntervention } from "./behavior"

const TypeInterventionSelect = ({ field, form, onSelectTypeIntervention, ...props}) => {

const handleChange = (value) => {/*console.log(value)*/}

  return (
    <AsyncSelect
      onChange={option => {
        if(form !== undefined){
          form.setFieldValue(field.name, option.value)
        }        
        if(onSelectTypeIntervention !== undefined ){
          onSelectTypeIntervention(option.value, form)
        }
      }}
      defaultOptions
      cacheOptions
      onInputChange={handleChange}
      loadOptions={getAllTypeIntervention}
      />
  )
}

export default TypeInterventionSelect