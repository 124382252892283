
const Pagination = ({handleSelectPage, items}) => {

  console.log(items)

  return <nav aria-label="Page navigation example">
  <ul className="pagination">
    {items.map((item, k) => <PaginationItem key={k} data={item} handler={handleSelectPage}/>)}
  </ul>
</nav>
}

function PaginationItem({data : {url,label,active}, handler}) {
  
  return  <li className={`page-item ${active ? 'active' : ''}`}>
            <button className={`page-link ${url != null ? '' : 'disabled'} `}  onClick={()=> handler(label)}>
              {label}
            </button>
          </li>
}

Pagination.defaultProps = {
  current: 1,
}

export default Pagination