import WebClient from "../../../../utils/WebClient";

export function getAllTypeIntervention() {
  return WebClient.get(`/settings/intervention/types`).then(response => {
    return response.data.map(item => {
      return {
        label: item.libelle,
        value: item
      }
    })
  })
}