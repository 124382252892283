import PropTypes from 'prop-types'

const PeriodeMissionLibelle  = ({value}) => {
  let libelle = ""
  switch (value) {
    case 'D' : libelle = "Jour(s)"; break;
    case 'W' : libelle = "Semaine(s)"; break;
    case 'M' : libelle = "Mois"; break;
    default : libelle = "Période non définie"
  } 

  return (<span>{libelle}</span>)
}

PeriodeMissionLibelle.propTypes = {
  value: PropTypes.oneOf(["D","W","M"])
}

export default PeriodeMissionLibelle