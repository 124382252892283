import { Fragment, useState } from "react"
import { useDispatch } from "react-redux"
import { useQuery } from "react-query"
import { getListeFactureProforma } from "../action"
import { NOTIFICATION_ERROR } from "../../../reducer/notificationRx"
import NoInternet from "../../../component/NoInternet"
import SkeletonLoader from "../../../component/SkeletonLoader"
import { useNavigate } from "react-router-dom"
import IasMenu from "../../../routes/web"
import Pagination from "../../../component/Pagination"
import FactureSearchBox from "./FactureSearchBox"
import parseISO from "date-fns/parseISO"
import format from "date-fns/format"
import StatutDescription from "../../../component/StatutDescription"

const ListeFacturePage = () => {

  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  
  const { isLoading, isError, data } = useQuery(['factures','proforma',page], ()=> getListeFactureProforma(page), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération des factures'})}
  })

  if(isError){
    return <NoInternet />
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Factures</h3>
          <FactureSearchBox />
          <hr/>
          <div className="table-responsive">
            <table className="table table-hover table-sm expandable-table">
              <thead>
                <tr className="table-info">
                  <th>N° Proforma</th>
                  <th>N° Facture</th>
                  <th>Date</th>
                  <th>Objet</th>
                  <th>Client</th>
                  <th>Statut</th>
                  <th className="text-right">Montant</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? 
                <tr><td colSpan={4}><SkeletonLoader /></td></tr>
                : 
                <Fragment>{data.data.data.map((item, k) => <FactureLine item={item} key={k} />)}</Fragment> }
              </tbody>
            </table>
            {data && <Pagination handleSelectPage={setPage} items={data.data.links} />}
          </div>
        </div>
      </div>
    </div>
  )
}


const FactureLine = ({item}) => {
  const navigate = useNavigate()

  return (
    <tr onClick={() => navigate(IasMenu.factures.details.replace(':reference',item.referenceproforma))}>
      <td style={{lineHeight: "0"}}> {item.referenceproforma} </td>
      <td>{item.referencefacture}</td>
      <td>{format(parseISO(item.creationproforma), "dd/MM/yyyy HH:mm:ss")}</td>
      <td>{item.objet}</td>
      <td>{item.partenaire.raisonsociale}</td>
      <td><StatutDescription statut={item.etat} /> </td>
      <td className="text-right">{Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(item.montantht + (item.isexonere ? item.tva : 0))}</td>
    </tr>)
}

export default ListeFacturePage