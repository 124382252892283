import AsyncSelect from 'react-select/async';
import { listeChauffeur as searchChauffeur } from "./behavior"

const ChauffeurSelect = ({ field, form, onSelectChauffeur, ...props}) => {

  const handleChange = (value) => {console.log(value)}

  return (
    <AsyncSelect
      onChange={option => {
        if(form !== undefined){
          form.setFieldValue(field.name, option.value)
        }        
        if(onSelectChauffeur !== undefined ){
          onSelectChauffeur(option.value, form)
        }
      }}
      defaultOptions
      cacheOptions
      onInputChange={handleChange}
      loadOptions={searchChauffeur}
      />
  )
}

export default ChauffeurSelect