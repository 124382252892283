import AsyncSelect from 'react-select/async';
import { searchPartner } from "./behavior"

const PartnerSelect = ({ field, form, onSelectPartner, ...props}) => {

  const handleChange = (value) => {console.log(value)}

  return (
    <AsyncSelect
      onChange={option => {
        if(form !== undefined){
          form.setFieldValue(field.name, option.value)
        }        
        if(onSelectPartner !== undefined ){
          onSelectPartner(option.value, form)
        }
      }}
      defaultOptions
      cacheOptions
      onInputChange={handleChange}
      loadOptions={searchPartner}
      />
  )
}

export default PartnerSelect