import { Fragment, useState } from "react"
import { useDispatch } from "react-redux"
import { useQuery} from 'react-query'
import { NOTIFICATION_ERROR } from "../../reducer/notificationRx"
import { getListeReparation } from "./actions"
import NoInternet from "../../component/NoInternet"
import SkeletonLoader from "../../component/SkeletonLoader"
import Pagination from "../../component/Pagination"
import { useNavigate } from "react-router-dom"
import IasMenu from "../../routes/web"
import { format, parseISO } from "date-fns"
import ModalContent from "../../component/ModalContent"
import InterventionForm from "./InterventionForm"

const ListeInterventionPage = ({disponible}) => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  let search = ""
  const defaultIntervention = {id: 0, vehiculeId: 0, typeInterventionId: 0, cout: 0, dateDebut: new Date(), dateFin: new Date(), changeStatut: false}

  const { isLoading, isError, data } = useQuery(['reparation','liste',page], ()=> getListeReparation(page, search), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération des réparations'})}
  })

  if(isError){
    return <NoInternet />
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Réparations</h3>
            <ModalContent 
              component={<InterventionForm intervention={defaultIntervention} />} 
              title="Nouvelle intervention" 
              withCloseButton={false} 
              lauchnButtonTitle="Nouvelle" 
              />
          <hr/>
          <div className="table-responsive">
            <table className="table table-hover table-sm expandable-table">
              <thead>
                <tr className="table-info">
                  <th>Immatriculation</th>
                  <th>Nature</th>
                  <th>Début</th>
                  <th>Fin</th>
                  <th>Coût</th>
                  <th>Fournisseur</th>
                  <th>Ref. facture</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? 
                <tr><td colSpan={7}><SkeletonLoader /></td></tr>
                : 
                <Fragment>
                  {data.data.data.map((item, k) => <ReparationLine item={item} key={k} />)}
                </Fragment> }
              </tbody>
            </table>
            {data && <Pagination handleSelectPage={setPage} items={data.data.links} />}
          </div>
        </div>
      </div>
    </div>
  )
}

const ReparationLine = ({item}) => {
  const navigate = useNavigate()
  
  return (
    <tr onClick={() => alert(JSON.stringify(item))}>
      <td style={{lineHeight: "0"}}> {item.vehicule.immatriculation} </td>
      <td>{item.type_intervention.libelle}</td>
      <td>{format(parseISO(item.debut), "dd/MM/yyyy")}</td>
      <td>{format(parseISO(item.fin), "dd/MM/yyyy")}</td>
      <td>{item.cout}</td>
      <td>{item.partenaire?.raisonsociale}</td>
      <td>{JSON.stringify(item.piece_fournisseur)}</td>
    </tr>)
}

ListeInterventionPage.defaultProps = {
  disponible: false,
}

export default ListeInterventionPage