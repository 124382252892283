import WebClient from "../../utils/WebClient"

export function getListeProduit(page, search){
  return WebClient.get(`/produits/liste?page=${page}&search=${search}`)
}
export function getProduit(reference){
  return WebClient.get(`/produits/${reference}/details`)
}
export function getFamilleProduit(){
  return WebClient.get(`/produits/famille/liste`)
}