import { Field, Form, Formik } from "formik"
import { useState } from "react"
import DateInput from "../../../component/Form/common/DateInput"
import FactureModeSelector from "../../../component/Form/Facture/select"

const FactureSearchBox = () => {
  const [mode, setMode] = useState("ALL")

  return (
    <Formik
      initialValues={{ datedebut: new Date(), datefin: new Date(), reference: "", type: mode}}
      
      onSubmit={(values, actions) => {
        values.type = mode
        console.log(values)
      }}
    >
      <Form>
        <div className="row">
          <div className="col-lg-2">
            <div className="form-group">
              <label htmlFor="datedebut">Date début</label>
              <Field component={DateInput} className="form-control" name="datedebut" placeholder="JJ/MM/AAAA"/>
            </div>
          </div>          
          <div className="col-lg-2">
            <div className="form-group">
              <label htmlFor="datefin">Date fin</label>
              <Field component={DateInput} className="form-control" name="datefin" placeholder="JJ/MM/AAAA"/>
            </div>
          </div>          
          <div className="col-lg-3">
            <div className="form-group">
              <label htmlFor="reference">Référence</label>
              <Field as="input" className="form-control" name="reference" placeholder="numéro de facture"/>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group">
              <FactureModeSelector label="Type de facture" withAll behavior={setMode} />
            </div>
          </div>
          <div className="col-lg-1">
            <br/>
            <button className="btn btn-primary mr-2" type="submit">Rechercher</button>
          </div>          
      </div>
      </Form>
    </Formik>
  )
}

export default FactureSearchBox