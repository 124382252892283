import { format, parseISO } from "date-fns"
import { Fragment, useState } from "react"
import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import NoInternet from "../../../component/NoInternet"
import Pagination from "../../../component/Pagination"
import SkeletonLoader from "../../../component/SkeletonLoader"
import { NOTIFICATION_ERROR } from "../../../reducer/notificationRx"
import { getListeChauffeur } from "../action"
import { useNavigate } from 'react-router-dom'

const ChauffeurListePage = () => {

  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const { isLoading, isError, data } = useQuery(['chauffeurs',page], ()=> getListeChauffeur(page), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération des chauffeurs'})}
  })

  if(isError){
    return <NoInternet />
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Chauffeurs</h3>
          <hr/>
          <div className="table-responsive">
            <table className="table table-hover table-sm expandable-table">
              <thead>
                <tr className="table-info">
                  <th>Nom et prénoms</th>
                  <th>N° Permis</th>
                  <th>Exp permis C</th>
                  <th>Exp permis D</th>
                  <th>Exp permis E</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? 
                <tr><td colSpan={5}><SkeletonLoader /></td></tr>
                : 
                <Fragment>{data.data.data.map((item, k) => <ChauffeurLine item={item} key={k} />)}</Fragment> }
              </tbody>
            </table>
            {data && <Pagination handleSelectPage={setPage} items={data.data.links} />}
          </div>
        </div>
      </div>
    </div>
  )
}

const ChauffeurLine = ({item}) => {  
  const navigate = useNavigate()

  return (
    <tr onClick={() => {/*navigate(IasMenu.rh.personnel.details.replace(':IdPersonne',item.matricule))*/}}>
      <td style={{lineHeight: "0"}}>{item.employe.nom} {item.employe.prenoms}</td>
      <td>{item.permis}</td>
      <td>{item.expiration_c ? format(parseISO(item.expiration_c), "dd/MM/yyyy") : "Non renseigné"}</td>
      <td>{item.expiration_d ? format(parseISO(item.expiration_d), "dd/MM/yyyy") : "Non renseigné"}</td>
      <td>{item.expiration_e ? format(parseISO(item.expiration_e), "dd/MM/yyyy") : "Non renseigné"}</td>
    </tr>)
}

export default ChauffeurListePage