
export const TYPE_UTILISATEUR = "1";

export const ETAT_ACTIF = "1";
export const ETAT_INACTIF = "0";
export const AUTRE_NON_DEFINI = "0";

export const MISSION_COMMANDEE = "100";
export const MISSION_EN_COURS = "101";
export const MISSION_TERMINEE = "102";
export const MISSION_ANNULEE = "103";
export const MISSION_TERMINEE_SOLDEE = "104";

export const PIECE_COMPTABLE_PRO_FORMA = "200";
export const PIECE_COMPTABLE_FACTURE_SANS_BL = "201";
export const PIECE_COMPTABLE_FACTURE_AVEC_BL = "202";
export const PIECE_COMPTABLE_FACTURE_PAYEE = "203";
export const PIECE_COMPTABLE_FACTURE_ANNULEE = "204";
export const PIECE_COMPTABLE_BON_COMMANDE = "205";
export const PIECE_COMPTABLE_BON_COMMANDE_VALIDE = "206";
export const PIECE_COMPTABLE_FACTURE_PARTIEL_PAYEE = "207";

export const VEHICULE_ACTIF = 300;
export const VEHICULE_VENDU = 301;
export const VEHICULE_ENDOMAGE = 302;
export const VEHICULE_RESERVE_MISSION = 303;
export const VEHICULE_EN_MISSION = 304;
export const VEHICULE_AU_GARAGE = 305;

export const PERSONNEL_ACTIF = "400";
export const PERSONNEL_TEMPORAIRE = "401";
export const PERSONNEL_DEMISSIONNE = "402";

const StatutDescription = ({statut, className}) => {
  
    let details = ""

    switch (statut){
        case PIECE_COMPTABLE_PRO_FORMA : details = "Facture pro forma"; break;
        case PIECE_COMPTABLE_FACTURE_AVEC_BL : details = "Facture (Impayé)"; break;
        case PIECE_COMPTABLE_FACTURE_SANS_BL : details = "Facture non livrée (Impayé)"; break;
        case PIECE_COMPTABLE_FACTURE_PAYEE : details = "Facture (Payé)"; break;
        case PIECE_COMPTABLE_FACTURE_PARTIEL_PAYEE : details = "Facture avancée (Paiement partiel)"; break;
        case PIECE_COMPTABLE_FACTURE_ANNULEE : details = "Facture Annulée"; break;
        case PIECE_COMPTABLE_BON_COMMANDE : details = "Bon de commande (Non Signé)"; break;
        case PIECE_COMPTABLE_BON_COMMANDE_VALIDE : details = "Bon de commande (Signé)"; break;

        case MISSION_COMMANDEE : details = "Mission commandée"; break;
        case MISSION_EN_COURS : details = "Mission en cours"; break;
        case MISSION_TERMINEE : details = "Mission terminée"; break;
        case MISSION_ANNULEE : details = "Mission annulée"; break;

      case VEHICULE_ACTIF : details = "Véhicule actif"; break;
      case VEHICULE_VENDU : details = "Véhicule vendu"; break;
      case VEHICULE_ENDOMAGE : details = "Véhicule hors d'usage"; break;
      case VEHICULE_RESERVE_MISSION : details = "Véhicule réservé"; break;
      case VEHICULE_EN_MISSION : details = "Véhicule en mission"; break;
      case VEHICULE_AU_GARAGE : details = "Véhicule au garage"; break;

      default: details = "Statut non défini"
    }
    return (<span className={className.toString()}>{details}</span>)
}

StatutDescription.defaultProps = {
  className: "",
}

export default StatutDescription