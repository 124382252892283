import { connect, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import AppWrapper from "./AppWrapper";
import LoginPage from "./pages/Auth/LoginPage";
import RequireAuth from "./pages/Auth/RequireAuth";
import AuthRx from "./reducer/authRx";
import IasMenu from "./routes/web";


function App({loginHandler}) {
  const user = useSelector(state => state.user)

  return (
    <div className="container-scroller">
      <Routes>        
        <Route path={IasMenu.auth.login} exact element={ <LoginPage /> } />
        <Route 
          path="*" 
          element={<RequireAuth><AppWrapper /></RequireAuth>} 
          />
      </Routes>
      
    </div>
  );
}

export default App