import AsyncSelect from 'react-select/async';
import { searchVehicule } from "./behavior"

const VehiculeSelect = ({ field, form, onSelectVehicule, ...props}) => {

  const handleChange = (value) => {/*console.log(value)*/}

  return (
    <AsyncSelect
      onChange={option => {
        if(form !== undefined){
          form.setFieldValue(field.name, option.value)
        }        
        if(onSelectVehicule !== undefined ){
          onSelectVehicule(option.value, form)
        }
      }}
      defaultOptions
      cacheOptions
      onInputChange={handleChange}
      loadOptions={searchVehicule}
      />
  )
}

export default VehiculeSelect