
const InputGroup = ({children, prependText, appendText}) => {

  return (
    <div className="input-group input-group-sm">
      {prependText !== "" && <div className="input-group-prepend"><span className="input-group-text bg-primary text-white">{prependText}</span></div>}
        {children}
      {appendText !== "" && <div className="input-group-append"><span className="input-group-text">{appendText}</span></div>}
    </div>
  )
}
InputGroup.defaultProps = {
  prependText: "",
  appendText: ""
}

export default InputGroup