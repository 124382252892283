import { format } from 'date-fns';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const Dashboard = () => {
  const utilisateur = useSelector(state => state.user)
  const options = {
    title: {
      text: 'My chart'
    },
    series: [{
      data: [1, 2, 3]
    }]
  }   

  return (
  <Fragment>
  <div className="col-md-12 grid-margin">
    <div className="row">
      <div className="col-lg-8 mb-4 mb-xl-0">
        <h3 className="font-weight-bold">Bienvenue {utilisateur.employe.nom}, {utilisateur.employe.prenoms}</h3>
        <h6 className="font-weight-normal mb-0">All systems are running smoothly! You have <span className="text-primary">3 unread alerts!</span></h6>
      </div>
      <div className="col-lg-4">
        <div className="justify-content-end d-flex">{format(new Date(), "dd/MM/yyyy")}</div>
      </div>
    </div>
  </div>

  <div className='col-lg-12'>
    <div className="row">
      <div className="col-lg-6 grid-margin stretch-card">
        <div className="card tale-bg">
          <div className="card-people mt-auto">
            <img src="https://www.bootstrapdash.com/demo/skydash/template/images/dashboard/people.svg" alt="people" />
            <div className="weather-info">
              <div className="d-flex">
                <div>
                  <h2 className="mb-0 font-weight-normal"><i className="icon-sun mr-2"></i>31<sup>C</sup></h2>
                </div>
                <div className="ml-2">
                  <h4 className="location font-weight-normal">Chicago</h4>
                  <h6 className="font-weight-normal">Illinois</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 grid-margin transparent">
        <div className="row">
          <div className="col-lg-6 mb-4 stretch-card transparent">
            <div className="card card-tale">
              <div className="card-body">
                <p className="mb-4">Today’s Bookings</p>
                <p className="fs-30 mb-2">4006</p>
                <p>10.00% (30 days)</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-4 stretch-card transparent">
            <div className="card card-dark-blue">
              <div className="card-body">
                <p className="mb-4">Total Bookings</p>
                <p className="fs-30 mb-2">61344</p>
                <p>22.00% (30 days)</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-0 stretch-card transparent">
            <div className="card card-light-blue">
              <div className="card-body">
                <p className="mb-4">Number of Meetings</p>
                <p className="fs-30 mb-2">34040</p>
                <p>2.00% (30 days)</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 stretch-card transparent">
            <div className="card card-light-danger">
              <div className="card-body">
                <p className="mb-4">Number of Clients</p>
                <p className="fs-30 mb-2">47033</p>
                <p>0.22% (30 days)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="col-lg-12 grid-margin stretch-card">
    <div className="card">
      <div className="card-body"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
        <p className="card-title">Order Details</p>
        <p className="font-weight-500">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>
        <div className="d-flex flex-wrap mb-5">
          <div className="mr-5 mt-3">
            <p className="text-muted">Order value</p>
            <h3 className="text-primary fs-30 font-weight-medium">12.3k</h3>
          </div>
          <div className="mr-5 mt-3">
            <p className="text-muted">Orders</p>
            <h3 className="text-primary fs-30 font-weight-medium">14k</h3>
          </div>
          <div className="mr-5 mt-3">
            <p className="text-muted">Users</p>
            <h3 className="text-primary fs-30 font-weight-medium">71.56%</h3>
          </div>
          <div className="mt-3">
            <p className="text-muted">Downloads</p>
            <h3 className="text-primary fs-30 font-weight-medium">34040</h3>
          </div> 
        </div>
        
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />

      </div>
    </div>
  </div>
  </Fragment>
  )
}

export default Dashboard;