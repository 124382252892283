import { FACTURE_LOCATION, FACTURE_PIECE } from "../../../pages/Factures/action"

const FactureModeSelector = ({behavior, withAll, label}) => {
  return (
  <div className="form-group">
    <label>{label}</label>
    <select className="form-control form-control-sm" onChange={(e) => behavior(e.target.value)}>
      {withAll && <option value={"ALL"}>Tous types</option>}
      <option value={FACTURE_PIECE}>Pièces</option>
      <option value={FACTURE_LOCATION}>Location</option>
    </select>
  </div>)
}

FactureModeSelector.defaultProps = {
  label: "Mode de facture",
  withAll: false,
}

export default FactureModeSelector