import { Field, Form, Formik } from "formik";
import { Fragment, useState } from "react";
import ChauffeurSelect from "../../../component/Form/common/ChauffeurSelect";
import PartnerSelect from "../../../component/Form/common/PartnerSelect";
import VehiculeSelect from "../../../component/Form/common/VehiculeSelect";
import { addNewMission, PL, VL } from "../action";
import { IasValidIcon, IasWarningIcon } from "../../../component/icons/IasIcons";
import DateInput from "../../../component/Form/common/DateInput";
import { format, compareAsc, differenceInDays, parseISO } from "date-fns";
import PeriodeMissionLibelle from "../common/PeriodeMissionString";
import ToggleSwitch from "../../../component/Form/common/ToggleSwitch";
import { LineInfo } from "../../../component/LineInfo";
import IasButtonSubmit from "../../../component/Form/common/IasButtonSubmit";
import StatutDescription from "../../../component/StatutDescription";
import { useDispatch } from "react-redux";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "../../../reducer/notificationRx";

const NouvelleMissionPage = () => {

  const [partner, setPartner] = useState({})
  const [vehicule, setVehicule] = useState({})
  const [chauffeur, setChauffeur] = useState({})
  const dispatch = useDispatch()

  return (
  <div className="col-lg-12">    
    <div className="card px-2">
      <div className="card-body">
        <h4 className="card-title">Nouvelle Mission</h4>
        <hr />
        <div className="container-fluid">
          <Formik
            initialValues={{typeMission: 0, id: 0, destination: "", partnerId: 0, vehiculeId: 0, chauffeurId: 0, dateDebut: new Date(), dateFin: new Date(), periode: "D", duree: 1, tarif: 100, withPerdiem: false, perdiem: 0}}
            validate={(values) => {
              const errors = {}
              if(values.objet === ""){errors.objet = "Objet de la facture vide"}
              if(values.vehiculeId === 0){errors.vehiculeId = "Sélectionnez un véhicule"}
              if(values.chauffeurId === 0){errors.chauffeurId = "Sélectionnez un chauffeur"}
              if(values.partnerId === 0){errors.partnerId = "Sélectionnez un client"}
              if(values.typeMission === 0){errors.typeMission = "Veuillez sélectionner le type de mission VL/PL"}
              if(values.destination === ""){errors.destination = "Veuillez saisir une destination"}
              if(compareAsc(values.dateDebut, values.dateFin) > 0) {errors.dateDebut = "La date début est supérieur à la date de fin"}
              return errors
            }}
            onSubmit={(values, actions) => {
              addNewMission(values).then(response => {
                dispatch({type: NOTIFICATION_SUCCESS, payload: JSON.stringify(response.data)})
              }).catch(error => {
                dispatch({type: NOTIFICATION_ERROR, payload: error.message})
              }).finally(() => actions.setSubmitting(false))
            }}
            >
            {({isSubmitting, errors, values}) => (
            <Form>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                      <label htmlFor="delai">Type de mission</label>
                      <Field as="select" name="typeMission" className="form-control form-control-sm">
                        <option value="">Choisir le type de mission</option>
                        <option value={VL}>Véhicule Léger</option>
                        <option value={PL}>Poids Lourd</option>
                      </Field>
                      {errors.typeMission && <span className="text-danger text-small">{errors.typeMission}</span>}
                    </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="delais">Client</label>
                    <Field name="partnerId" className="form-control form-control-sm" component={PartnerSelect}  onSelectPartner={(partner, form)=>{
                      setPartner(partner)
                      form.setFieldValue("partnerId", partner.id)
                    }} /> 
                    {errors.partnerId && <span className="text-danger text-small">{errors.partnerId}</span>}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="destination">Destinations</label>
                    <Field name="destination" as="input" className="form-control form-control-sm" />
                    {errors.destination && <span className="text-danger text-small">{errors.destination}</span>}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="dateDebut">Date début</label>
                    <Field name="dateDebut" className="form-control form-control-sm" component={DateInput}/>
                    {errors.dateDebut && <span className="text-danger text-small">{errors.dateDebut}</span>}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="dateFin">Date fin</label>
                    <Field name="dateFin" className="form-control form-control-sm" component={DateInput}/>
                    {errors.dateFin && <span className="text-danger text-small">{errors.dateFin}</span>}
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <label htmlFor="duree">Durée</label>
                    <Field name="duree" className="form-control form-control-sm" as="input" type="number" /> {/*differenceInDays(values.dateFin, values.dateDebut)+1*/}
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <label htmlFor="periode">&nbsp;</label>
                    <Field name="periode" as="select" className="form-control form-control-sm">
                      <option value="D">Jours</option>
                      <option value="W">Semaines</option>
                      <option value="M">Mois</option>
                    </Field>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="tarif">Tarif unitaire (FCFA)</label>
                    <Field name="tarif" className="form-control form-control-sm" as="input" type="number" />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <label htmlFor="">Prix total</label>
                    <h2><b>{Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(values.tarif * values.duree)}</b> <small>{values.duree} <PeriodeMissionLibelle value={values.periode} /> </small></h2>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="vehiculeId">Véhicule</label>
                    <Field name="vehiculeId" className="form-control form-control-sm" component={VehiculeSelect}  onSelectVehicule={(vehicule, form)=>{
                      setVehicule(vehicule)
                      form.setFieldValue("vehiculeId", vehicule.id)
                    }} />
                    {errors.vehiculeId && <span className="text-danger text-small">{errors.vehiculeId}</span>}
                  </div>
                  {vehicule.id && <div className="py-2">
                    <LineInfo 
                      libelle="Marque/Modèle : " 
                      value={`${vehicule.marque} / ${vehicule.typecommercial}`} 
                      />
                    <LineInfo 
                      libelle="Date fin visite : " 
                      value={format(parseISO(vehicule.visite),"dd/MM/yyyy")} 
                      icons={compareAsc(parseISO(vehicule.visite), values.dateFin) > 0 ? IasValidIcon : IasWarningIcon} 
                      iconColor={compareAsc(parseISO(vehicule.visite), values.dateFin) > 0 ? "green" : "red"} 
                      />
                    <LineInfo 
                      libelle="Date fin assurance : " 
                      value={format(parseISO(vehicule.assurance),"dd/MM/yyyy")} 
                      icons={compareAsc(parseISO(vehicule.assurance), values.dateFin) > 0 ? IasValidIcon : IasWarningIcon} 
                      iconColor={compareAsc(parseISO(vehicule.assurance), values.dateFin) > 0 ? "green" : "red"} 
                      />
                    <LineInfo 
                      libelle="Couleur/Place : " 
                      value={`${vehicule.couleur} / ${vehicule.nbreplace}`} 
                      />
                    <LineInfo 
                      libelle="Energie : " 
                      value={vehicule.energie} 
                      />
                    <LineInfo 
                      libelle="Statut : " 
                      value={<StatutDescription className="text-info" statut={vehicule.status} />}
                      />
                  </div>}
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="chauffeurId">Chauffeur</label>
                    <Field name="chauffeurId" className="form-control" component={ChauffeurSelect}  onSelectChauffeur={(chauffeur, form)=>{
                      setChauffeur(chauffeur)
                      form.setFieldValue("chauffeurId", chauffeur.id)
                    }} /> 
                    {errors.chauffeurId && <span className="text-danger text-small">{errors.chauffeurId}</span>}
                  </div>
                  {chauffeur.employe_id && <div className="py-2">
                    <LineInfo 
                      libelle="Permis : " 
                      value={chauffeur.permis} 
                      />
                    <LineInfo 
                      libelle="Date expiration catégorie C : " 
                      value={chauffeur.expiration_c ? format(parseISO(chauffeur.expiration_c),"dd/MM/yyyy"):"Non défini"} 
                      icons={chauffeur.expiration_c ? (compareAsc(parseISO(chauffeur.expiration_c), values.dateFin) > 0 ? IasValidIcon : IasWarningIcon) : ""} 
                      iconColor={chauffeur.expiration_c ? (compareAsc(parseISO(chauffeur.expiration_c), values.dateFin) > 0 ? "green" : "red") : ""} 
                      />
                    <LineInfo 
                      libelle="Date expiration catégorie D : " 
                      value={chauffeur.expiration_d ? format(parseISO(chauffeur.expiration_d),"dd/MM/yyyy"):"Non défini"} 
                      icons={chauffeur.expiration_d ? (compareAsc(parseISO(chauffeur.expiration_d), values.dateFin) > 0 ? IasValidIcon : IasWarningIcon) : ""} 
                      iconColor={chauffeur.expiration_d ? (compareAsc(parseISO(chauffeur.expiration_d), values.dateFin) > 0 ? "green" : "red") : ""} 
                      />
                    <LineInfo 
                      libelle="Date expiration catégorie E : " 
                      value={chauffeur.expiration_e ? format(parseISO(chauffeur.expiration_e),"dd/MM/yyyy"):"Non défini"} 
                      icons={chauffeur.expiration_e ? (compareAsc(parseISO(chauffeur.expiration_e), values.dateFin) > 0 ? IasValidIcon : IasWarningIcon) : ""} 
                      iconColor={chauffeur.expiration_e ? (compareAsc(parseISO(chauffeur.expiration_e), values.dateFin) > 0 ? "green" : "red") : ""} 
                      />
                    <hr />
                    <div className="row">
                      <div className="col-lg-4">            
                        <Field component={ToggleSwitch} name="withPerdiem" label=""/>
                        <label style={{display:"inline-block", paddingLeft: "0.5rem"}}>Perdiems</label> 
                      </div>
                      {values.withPerdiem && 
                        <Fragment>
                          <div className="col-lg-3">
                            <Field name="perdiem" placeholder="Montant des perdiem par période" className="form-control form-control-sm" as="input" type="number" />
                          </div>
                          <div className="col-lg-5">
                            /<PeriodeMissionLibelle value={values.periode} />
                            <br/>
                            <small>Soit {Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(values.perdiem * values.duree)}</small>
                          </div>
                        </Fragment>}
                    </div>
                  </div>}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="observation">Observations</label>
                    <Field name="observation" className="form-control form-control-sm" as="textarea" row="4" placeholder="Observation de la mission"/>
                  </div>
                </div>
              </div>              
              <hr />
              <div className="container-fluid w-100">
                <IasButtonSubmit className="btn btn-success float-right mt-4 ml-2" texte="Enregistrer la mision" isSubmitting={isSubmitting} />
              </div>
            </Form>)}
          </Formik>
        </div>
      </div>
    </div>
  </div>
  )
}

export default NouvelleMissionPage