import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import Loading from "../../component/Loading";
import { NOTIFICATION_ERROR } from "../../reducer/notificationRx";
import {getProduit} from './actions'
import { LineInfo } from "../../component/LineInfo";
import ModalContent from "../../component/ModalContent";
import ProduitForm from "./form/ProduitForm";
import DropdownButton from "../../component/DropdownButton";

const DetailsProductPage = () => {
  
  let params = useParams();
  const dispatch = useDispatch()
  
  const { isLoading, isError, data } = useQuery(['produits','details',params.reference], ()=> getProduit(params.reference), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération du détail du produit'})}
  })

  if(isLoading) {return <div>Chargement ... <Loading /></div>}
  
  return (
    <div className="col-lg-12 grid-margin stretch-card">  
      <div className="container-fluid px-2">      
        <div className="row">
          
          <div className="col-md-4">            
            <div className="card">
              <div className="ml-xl-4 mt-3">
                <p className="card-title">Détails produit</p>
                <hr/>
                <div className="card-body">  
                  <LineInfo libelle="Reférence :" value={data.data.reference} />
                  <LineInfo libelle="Famille :" value={data.data.famille.libelle} />
                  <LineInfo libelle="Libellé :" value={data.data.libelle} />
                  <LineInfo libelle="Disponibilité :" value={data.data.isdisponible ? 'Disponible' : 'Indisponible'} />
                  <LineInfo libelle="Prix Unitaire :" value={Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(data.data.prixunitaire)} />
                  <LineInfo libelle="Stock :" value={data.data.stock} />
                </div>
                <hr/>
                <ModalContent component={<ProduitForm produit={data.data} />} title="Modification produit" withCloseButton={false} lauchnButtonTitle="Modifier le produit" />
              </div>              
            </div>
          </div>

          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                {JSON.stringify(data.data)}
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    )
}

export default DetailsProductPage