import WebClient from "../../../../utils/WebClient";

export function listeChauffeur(search) {
  return WebClient.get(`/employes/chauffeurs/liste?fullname=${search}`).then(response => {
    return response.data.data.map(item => {
      return {
        label: `${item.employe.nom} ${item.employe.prenoms}`,
        value: item
      }
    })
  })
}