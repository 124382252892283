import WebClient from "../../utils/WebClient"

export function getListeVehicule(page, search){
  return WebClient.get(`/vehicules/liste?page=${page}&search=${search}`)
}
export function getListeVehiculeDisponible(page, search){
  return WebClient.get(`/vehicules/liste/disponibles?page=${page}&search=${search}`)
}
export function getListeVehiculeIndisponible(page, search){
  return WebClient.get(`/vehicules/liste/indisponibles?page=${page}&search=${search}`)
}
export function getVehiculeDetails(immatriculation){
  return WebClient.get(`/vehicules/${immatriculation}/details`)
}
export function getGenreVehiculeListe(){
  return WebClient.get(`/vehicules/genres`)
}