import WebClient from "../../../../utils/WebClient";

export function searchProduct(search) {
  return WebClient.get(`/produits/search?keyword=${search}`).then(response => {
    return response.data.data.map(item => {
      return {
        label: `${item.reference} - ${item.libelle}`,
        value: item
      }
    })
  })
}