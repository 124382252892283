import Loading from "../../../Loading"
import PropsType from 'prop-types'

const IasButtonSubmit = ({texte, className, isSubmitting}) => {

  if(isSubmitting){
    return <Loading />
  }

  return <button type="submit" className={className.toString()}>{texte}</button>
}

IasButtonSubmit.defaultProps = {
  className: "btn btn-success",
  isSubmitting: false,
  texte: "Enregistrer"
}

IasButtonSubmit.propsType = {
  className: PropsType.string,
  isSubmitting: PropsType.bool.isRequired,
  texte: PropsType.string,
}

export default IasButtonSubmit