import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import withMenu from './withMenu';

const SideBar = ({data, selected, change}) => {

  return(
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        { data.map(
          (menuItem, k) => 
            <MenuItem 
              key={k} 
              line={menuItem} 
              selected={selected} 
              change={change} />)
        }
      </ul>
    </nav>
  )
}

function MenuItem({line, selected, change}){

  const {item, link, children, icon} = line

  let show = ""
  if(selected != null){
    show = item === selected.item ? "active" : ""
  }

  let SubMenu = <Fragment /> 

  let Arrow = <Fragment />

  if(children.length > 0){
    Arrow = <i className="menu-arrow" />
    SubMenu = (
      <div className={`collapse ${show === "" ? '' : "show"}`}>
        <ul className="nav flex-column sub-menu">
          {
            children.map((menu, k) => 
              <li key={k} className="nav-item">
                <Link className="nav-link" to={menu.link}> {menu.item} </Link>
              </li>
            )
          }          
        </ul>
      </div>
    )
  }

  const selectedMenu = (arg) => change(arg)

  return (
    <li className={`nav-item ${show}`}>
      <Link className="nav-link" to={link} onClick={(e) => {selectedMenu(line)}}>
        <i className={`menu-icon ${icon}`}></i>
        <span className="menu-title">{item}</span>
        {Arrow}
      </Link>
      {SubMenu}
    </li>
  )
}

export default withMenu(SideBar);