export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS"
export const NOTIFICATION_ERROR   = "NOTIFICATION_ERROR"
export const NOTIFICATION_INFO    = "NOTIFICATION_INFO"
export const NOTIFICATION_WARNING = "NOTIFICATION_WARNING"
export const NOTIFICATION_HIDE    = "NOTIFICATION_HIDE"


const initialState = {type: "", message : "", title: "", show: false};
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_ERROR :
      return {
        type: 'danger',
        title: 'Erreur',
        message: action.payload,
        show: true
      }
    case NOTIFICATION_INFO :
      return {
        type: 'primary',
        title: 'Information',
        message: action.payload,
        show: true
      }
    case NOTIFICATION_SUCCESS :
      return {
        type: 'success',
        title: 'Succès',
        message: action.payload,
        show: true
      }
    case NOTIFICATION_WARNING :
      return {
        type: 'warning',
        title: 'Attention',
        message: action.payload,
        show: true
      }
    case NOTIFICATION_HIDE :
      return initialState
    default :
      return {...state}
  }
}

const NotificationRx = {
  reducer : reducer,
}

export default NotificationRx;