import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import Loading from "../../component/Loading";
import { NOTIFICATION_ERROR } from "../../reducer/notificationRx";
import { getVehiculeDetails } from "./actions";
import { LineInfo } from "../../component/LineInfo";
import ModalContent from "../../component/ModalContent";
import VehiculeForm from "./form/VehiculeForm";
import { compareAsc, format, parseISO } from "date-fns";
import { IasValidIcon, IasWarningIcon } from "../../component/icons/IasIcons";


const DetailsVehiculePage = ({}) => {
  let params = useParams();
  const dispatch = useDispatch()
  
  const { isLoading, isError, data } = useQuery(['vehicule','details',params.immatriculation], ()=> getVehiculeDetails(params.immatriculation), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: `Erreur de récupération du détail du véhicule ${params.immatriculation}`})}
  })

  if(isLoading) {return <div>Chargement ... <Loading /></div>}
  
  return (
    <div className="col-lg-12 grid-margin stretch-card">  
      <div className="container-fluid px-2">      
        <div className="row">
          
          <div className="col-md-4">            
            <div className="card">
              <div className="ml-xl-4 mt-3">
                <p className="card-title">Détails Véhicule {params.immatriculation}</p>
                <hr/>
                <div className="card-body">  
                  <LineInfo libelle="Immatriculation :" value={data.data.immatriculation} />
                  <LineInfo libelle="Marque :" value={data.data.marque} />
                  <LineInfo libelle="Modèle :" value={data.data.typecommercial} />
                  <LineInfo libelle="Genre :" value={data.data.genre.libelle} />
                  <LineInfo libelle="Couleur :" value={data.data.couleur} />
                  <LineInfo libelle="Nombre de places :" value={data.data.nbreplace} />
                  <LineInfo libelle="Carte grise :" value={data.data.cartegrise} />
                  <LineInfo 
                    libelle="Exp. Assurance :" 
                    value={format(parseISO(data.data.assurance),"dd/MM/yyyy")}
                    icons={compareAsc(parseISO(data.data.assurance), new Date()) > 0 ? IasValidIcon : IasWarningIcon} 
                    iconColor={compareAsc(parseISO(data.data.assurance), new Date()) > 0 ? "green" : "red"}
                    />
                  <LineInfo 
                    libelle="Exp. Visite :" 
                    value={format(parseISO(data.data.visite),"dd/MM/yyyy")}
                    icons={compareAsc(parseISO(data.data.visite), new Date()) > 0 ? IasValidIcon : IasWarningIcon} 
                    iconColor={compareAsc(parseISO(data.data.visite), new Date()) > 0 ? "green" : "red"}
                    />
                  <LineInfo libelle="Puissance fiscale :" value={`${data.data.puissancefiscale} chv`} />
                  <LineInfo libelle="Coût d'achat :" value={Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(data.data.coutachat)} />
                </div>
                <hr/>
                <ModalContent component={<VehiculeForm vehicule={data.data} />} withLargeModal title="Modification du véhicule" withCloseButton={false} lauchnButtonTitle="Modifier le véhicule" />
              </div>              
            </div>
          </div>

          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <p className="card-title mb-0">Interventions - Total : <span>{Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(data.data.interventions.reduce((prev, curr) => prev + curr.cout, data.data.coutachat))}</span></p>
                <div className="table-responsive">
                  <table className="table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Opération</th>
                        <th className="text-right">Crédit</th>
                        <th className="text-right">Débit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{format(parseISO(data.data.dateachat),"dd/MM/yyyy")}</td>
                        <td>Achat du véhicule</td>
                        <td className="text-right">0</td>
                        <td className="text-right">{new Intl.NumberFormat("fr-FR").format(data.data.coutachat)}</td>
                      </tr>
                      {data.data.interventions.map((item,k) => 
                        <tr key={k}>
                          <td>{format(parseISO(item.debut),"dd/MM/yyyy")}</td>
                          <td>{item.details}</td>
                          <td className="text-right">0</td>
                          <td className="text-right">{new Intl.NumberFormat("fr-FR").format(item.cout)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    )
}

export default DetailsVehiculePage