import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loading from "../../component/Loading";
import NoInternet from "../../component/NoInternet";
import { NOTIFICATION_ERROR } from "../../reducer/notificationRx";
import IasMenu from "../../routes/web";
import { getPartenaireByIdWithOrders } from "./action";

const ClientVisionPage = () => {

  let params = useParams();
  const [tab, setTab] = useState(0)
  const dispatch = useDispatch()

  const { isLoading, isError, data } = useQuery(['partenaire',params.partnerId], ()=> getPartenaireByIdWithOrders(params.partnerId), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération des clients'})}
  })

  if(isError){
    return <NoInternet />
  }

  if(isLoading){
    return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <Loading />
        </div>
      </div>
    </div>)
  }

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4">
              <div className="border-bottom text-center pb-4">
                <div className="mb-3">
                  <br />
                  <h3>{data.data.partner.raisonsociale}</h3>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="mb-0 mr-2 text-muted">{data.data.partner.comptecontribuable}</div>
                  </div>
                </div>
              </div>
              <div className="py-4">
                <p className="clearfix">
                  <span className="float-left">Statut</span>
                  <span className="float-right text-muted">Actif</span>
                </p>
                <p className="clearfix">
                  <span className="float-left">Téléphone</span>
                  <span className="float-right text-muted">{data.data.partner.telephone}</span>
                </p>
                <p className="clearfix">
                  <span className="float-left">Email</span>
                  <span className="float-right text-muted">{data.data.partner.email}</span>
                </p>
              </div>
            </div>
            <div className="offset-lg-1 col-lg-7">
              <div className="d-block d-md-flex justify-content-between mt-4 mt-md-0">
                <div className="text-center mt-4 mt-md-0">
                  <Link className="btn btn-primary mr-1" to={IasMenu.factures.fromPartner.replace(":partnerId",params.partnerId)}>Proforma</Link>
                  <Link className="btn btn-primary" to={"#"}>Mission</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="col-lg-12">
          <div className="mt-4 py-2">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link className={`nav-link ${tab === 0 ? 'active' : '' }`} onClick={() => {setTab(0)}} to="#"><b>Factures</b></Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${tab === 1 ? 'active' : '' }`} onClick={() => {setTab(1)}} to="#"><b>Missions</b></Link>
              </li>
            </ul>
          </div>
          <div className="card">
            <div className="card-body">
              { tab === 0 && <ListeFacturePartenaire /> }
              { tab === 1 && <ListeMissionPartenaire /> }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientVisionPage

const ListeMissionPartenaire = () => {
  let data = {data: []}
  return(
    <div className="table-responsive">
      <table className="table table-striped table-borderless">
        <thead>
          <tr>
            <th>Véhicule</th>
            <th>Date début</th>
            <th>Date fin</th>
            <th>Destinations</th>
            <th>Etat</th>
            <th className="text-right">Montant</th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((item,k) => 
            <tr key={k}>
              <td>{item.debut}</td>
              <td>{item.details}</td>
              <td>{item.details}</td>
              <td>{item.details}</td>
              <td>{item.details}</td>
              <td className="text-right">{new Intl.NumberFormat("fr-FR").format(item.cout)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

const ListeFacturePartenaire = () => {
  let data = {data: []}
  return(
  <div className="table-responsive">
    <table className="table table-striped table-borderless">
      <thead>
        <tr>
          <th>Référence</th>
          <th>Date</th>
          <th>Objet</th>
          <th>Statut</th>
          <th className="text-right">Montant</th>
        </tr>
      </thead>
      <tbody>
        {data.data.map((item,k) => 
          <tr key={k}>
            <td>{item.debut}</td>
            <td>{item.details}</td>
            <td>{item.details}</td>
            <td>{item.details}</td>
            <td>{item.details}</td>
            <td className="text-right">{new Intl.NumberFormat("fr-FR").format(item.cout)}</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
  )
}