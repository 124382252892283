import { format, parseISO } from "date-fns"
import { Fragment, useState } from "react"
import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import NoInternet from "../../component/NoInternet"
import Pagination from "../../component/Pagination"
import SkeletonLoader from "../../component/SkeletonLoader"
import StatutDescription from "../../component/StatutDescription"
import { NOTIFICATION_ERROR } from "../../reducer/notificationRx"
import IasMenu from "../../routes/web"
import { getListeVehicule, getListeVehiculeDisponible, getListeVehiculeIndisponible } from "./actions"
import VehiculeSearchBox from "./common/VehiculeSearchBox"

const ListeVehiculePage = ({disponible}) => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  let search = ""

  const getVehicules = disponible ? getListeVehiculeDisponible : getListeVehiculeIndisponible

  const { isLoading, isError, data } = useQuery(['vehicules','liste',disponible,page], ()=> getVehicules(page, search), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération des véhicules'})}
  })

  if(isError){
    return <NoInternet />
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Véhicules</h3>
          <VehiculeSearchBox />
          <hr/>
          <div className="table-responsive">
            <table className="table table-hover table-sm expandable-table">
              <thead>
                <tr className="table-info">
                  <th>Immatriculation</th>
                  <th>Marque</th>
                  <th>Modèle</th>
                  <th>Genre</th>
                  <th>Visite</th>
                  <th>Assurance</th>
                  <th>Statut</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? 
                <tr><td colSpan={7}><SkeletonLoader /></td></tr>
                : 
                <Fragment>{data.data.data.map((item, k) => <VehiculeLine item={item} key={k} />)}</Fragment> }
              </tbody>
            </table>
            {data && <Pagination handleSelectPage={setPage} items={data.data.links} />}
          </div>
        </div>
      </div>
    </div>
  )
}

const VehiculeLine = ({item}) => {
  const navigate = useNavigate()
  
  return (
    <tr onClick={() => navigate(IasMenu.vehicules.details.replace(':immatriculation',item.immatriculation.trim()))}>
      <td style={{lineHeight: "0"}}> {item.immatriculation} </td>
      <td>{item.marque}</td>
      <td>{item.typecommercial}</td>
      <td>{item.genre.libelle}</td>
      <td>{format(parseISO(item.visite), "dd/MM/yyyy")}</td>
      <td>{format(parseISO(item.assurance), "dd/MM/yyyy")}</td>
      <td><StatutDescription statut={item.status} /></td>
    </tr>)
}

ListeVehiculePage.defaultProps = {
  disponible: false,
}

export default ListeVehiculePage