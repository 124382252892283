import WebClient from "../../utils/WebClient"

export const FACTURE_PIECE = "FACTURE_PIECE"
export const FACTURE_LOCATION = "FACTURE_LOCATION"

export function getListeFactureProforma(page, search){
  return WebClient.get(`/factures/liste?page=${page}&search=${search}`)
}

export function getListeFactureDetails(reference){
  return WebClient.get(`/factures/${reference}/details`)
}