import { Formik, Form, Field } from "formik"
import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import IasButtonSubmit from "../../../component/Form/common/IasButtonSubmit"
import ToggleSwitch from "../../../component/Form/common/ToggleSwitch"
import Loading from "../../../component/Loading"
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "../../../reducer/notificationRx"
import {getFamilleProduit} from '../actions'

const ProduitForm = ({produit}) => {
  const dispatch = useDispatch()
  const disabled = produit.reference !== "" ? {disabled : "disabled"} : {}

  const { isLoading, isError, data } = useQuery(['famille'], getFamilleProduit, {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération des familles de produits'})}
  })

  if(isLoading){return <Loading />}

  return(
    <Formik
      initialValues={{...produit}}
      validate={(values) => {
        const errors = {}
        if(values.famille_id === 0) {errors.famille_id = "Famille obligatoire"}
        if(values.prixunitaire === 0) {errors.prixunitaire = "Prix unitaire obligatoire"}
        if(values.reference === "") {errors.reference = "Référence obligatoire"}
        return errors
      }}
      onSubmit={(values, actions) => {
        setTimeout(()=>{
          actions.setSubmitting(false);
          dispatch({type: NOTIFICATION_SUCCESS, payload: "Produit enregistré avec succès"})
        }, 10000);
      }}
      >
        {({isSubmitting, errors, values}) => (
        <Form>          
          <div className="form-group">
            <label htmlFor="reference">Référence</label>
            <Field as="input" className="form-control" name="reference" placeholder="Référence du produit" {...disabled}/>
            {errors.reference && <span className="text-danger text-small">{errors.reference}</span>}
          </div>          
          <div className="form-group">
            <label htmlFor="famille_id">Famille</label>
            <Field as="select" className="form-control" name="famille_id" defaultValue={produit.famille_id}>
              {data.data.map((item, k) => <option key={k} value={item.id}>{item.libelle}</option>)}
            </Field>
            {errors.famille_id && <span className="text-danger text-small">{errors.famille_id}</span>}
          </div>                    
          <div className="form-group">
            <label htmlFor="libelle">Libellé</label>
            <Field as="input" className="form-control" name="libelle" type="text" placeholder="Libellé du produit"/>
            {errors.prixunitaire && <span className="text-danger text-small">{errors.prixunitaire}</span>}
          </div>                  
          <div className="form-group">
            <label htmlFor="prixunitaire">Prix unitaire</label>
            <Field as="input" className="form-control" name="prixunitaire" type="number" placeholder="prix du produit"/>
            {errors.prixunitaire && <span className="text-danger text-small">{errors.prixunitaire}</span>}
          </div>               
          <div className="form-group">
            <label htmlFor="stock">Stock</label>
            <Field as="input" className="form-control" name="stock" type="number" placeholder="Stock actuel" {...disabled}/>
            {errors.prixunitaire && <span className="text-danger text-small">{errors.prixunitaire}</span>}
          </div>
          <div className="form-group">
            <Field component={ToggleSwitch} name="isdisponible" label="" defaultValue={false}/>
            <label style={{display:"inline-block", paddingLeft: "0.5rem"}}>Disponible</label> 
          </div>

          <div className="modal-footer">
            <IasButtonSubmit isSubmitting={isSubmitting} />
          </div>          
        </Form>)}
      </Formik>
  )
}

ProduitForm.defaultProps = {
  produit: {id:0, reference:"", isdisponible: false, libelle: "", prixunitaire: "", stock: 0, familleId: 0}
}

export default ProduitForm