import { Fragment, useState } from "react"
import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import NoInternet from "../../component/NoInternet"
import Pagination from "../../component/Pagination"
import SkeletonLoader from "../../component/SkeletonLoader"
import { NOTIFICATION_ERROR } from "../../reducer/notificationRx"
import IasMenu from "../../routes/web"
import { getListeClient } from "./action"

const ClientListePage = () => {

  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  
  const { isLoading, isError, data } = useQuery(['clients',page], ()=> getListeClient(page), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération des clients'})}
  })

  if(isError){
    return <NoInternet />
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Partenaire - clients</h3>
          <hr/>
          <div className="table-responsive">
            <table className="table table-hover table-sm expandable-table">
              <thead>
                <tr className="table-info">
                  <th>Raison sociale</th>
                  <th>Compte contribualble</th>
                  <th>Téléphone</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? 
                <tr><td colSpan={4}><SkeletonLoader /></td></tr>
                : 
                <Fragment>{data.data.data.map((item, k) => <PartnerLine item={item} key={k} />)}</Fragment> }
              </tbody>
            </table>
            {data && <Pagination handleSelectPage={setPage} items={data.data.links} />}
          </div>
        </div>
      </div>
    </div>
  )
}

const PartnerLine = ({item}) => {
  
  const navigate = useNavigate()

  return <tr onClick={() => navigate(IasMenu.partenaires.details.replace(':partnerId',item.id))}>
          <td style={{lineHeight: "0"}}> {item.raisonsociale} </td>
          <td>{item.comptecontribuable}</td>
          <td>{item.telephone}</td>
        </tr>
}

export default ClientListePage