import { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import './index.css'

const Notification = () => {

  const notification = useSelector(state => state.notification)
  const {type, title, message} = notification

  const [show, setShow] = useState(false)

  useEffect(()=>{
    if(notification.show){
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 5000)
    }
  }, [notification])

  return show && <Fragment>
    <div className={`alert alert-${type}`} role="alert" id="ias-notif">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>setShow(false)}>
        <span aria-hidden="true">&times;</span>
      </button>    
      <h4 className="alert-heading">{title}</h4>
      <hr/>
      <p className="mb-0">{message}</p>  
    </div>
  </Fragment>
}

export default Notification