import PropTypes from 'prop-types'
import './switch.css'

const ToggleSwitch = ({handleToggle, label, field, form, onSelectProduct, ...props}) => {

  return (
  <label className="switch">
    {label}
    <input 
      type="checkbox" 
      {...field}
      onChange={e => { 
        handleToggle?.(e.target.checked)
        field.onChange(e)
      }}
      />
    <span className="slider round"></span>
  </label>)
}

ToggleSwitch.defaultProps = {
  label: "",
}

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  handleToggle: PropTypes.func
}

export default ToggleSwitch