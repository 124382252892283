import WebClient from "../../../../utils/WebClient";

export function searchVehicule(search) {
  return WebClient.get(`/vehicules/search?immatriculation=${search}`).then(response => {
    return response.data.data.map(item => {
      return {
        label: `${item.immatriculation} - ${item.genre.libelle}`,
        value: item
      }
    })
  })
}