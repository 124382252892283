export const USER_LOGIN = "USER_LOGIN"

const initialState = {isConnected: false}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN :
      return {
        isConnected: true,
        ...action.payload
      }
    case "@@router/LOCATION_CHANGE" :
      return initialState
    default :
      return {...state}
  }
}

const AuthRx = {
  reducer : reducer,
}

export default AuthRx;