import React from "react";
import { useIsFetching } from "react-query";
import './index.css'

export default function LoadingBarRequest(){
  const isFetching = useIsFetching()

  return isFetching ? 
    <main id="loading-bar-request">
      <div className="loading-bar-container">
        <div className="loading-bar" />
      </div>
    </main> : null
}

LoadingBarRequest.defaultProps = {
  show: false
}