import { Fragment } from "react"
import Loading from "../Loading"

const DefaultLoadingPage = () => {

  return <Fragment>
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex align-items-center auth px-0" style={{backgroundColor:"#DDDDDD"}}>
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5" style={{borderRadius: "15px"}}>
              <div className="">
                <img src="/assets/images/logo-ias.png" width="400px" alt="logo" />
              </div>
              <br/>
              <Loading />
              <br/>
              <h5 className="text-center">Chargement en cours ...</h5>
            </div>
          </div>
        </div>       
      </div>
    </div>
  </Fragment>
}

export default DefaultLoadingPage