import React from 'react';
import { Link } from 'react-router-dom';
import LoadingBarRequest from '../component/LoadingBarRequest';

export default function TopBar(){

  return(  
  <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <Link className="navbar-brand brand-logo mr-5" to="#">
        <img src="/assets/images/logo-ias.png" className="mr-2" alt="logo"/>
      </Link>
    </div>
    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <ul className="navbar-nav navbar-nav-right">
        <li className="nav-item nav-profile dropdown">
          <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown" id="profileDropdown">
            <img src="/assets/images/user.png" alt="profile"/>
          </Link>
        </li>
        <li className="nav-item nav-settings d-none d-lg-flex">
          <Link className="dropdown" to="#"><i className="ti-power-off text-primary"></i>Déconnexion</Link>
        </li>
      </ul>
    </div>
    
    <LoadingBarRequest />
  </nav>)
}