import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {useNavigate} from 'react-router-dom'
import WebClient from '../../utils/WebClient';
import { USER_LOGIN } from '../../reducer/authRx';
import { useDispatch } from 'react-redux';
import IasMenu from '../../routes/web';
import IasButtonSubmit from '../../component/Form/common/IasButtonSubmit';

const LoginPage = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  return(
    <Formik
      initialValues={{ login: '', password: '' }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)    
        WebClient.post(`/login`,values).then((response) => {
          console.log("#9 redirect to home after connexion")

          localStorage.setItem("access_token", response.data.access)
          localStorage.setItem("refresh_token", response.data.refresh)
          dispatch({type: USER_LOGIN, payload: response.data.user})

          navigate(IasMenu.dashboard.base)
        }).catch(error => { console.log(error) }).finally(() => setSubmitting(false))
        
      }}
    >
      {({ error, isSubmitting }) =>
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-lg-4 mx-auto">
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    <div className="brand-logo">
                      <img src="/assets/images/logo-ias.png" alt="logo" />
                    </div>
                    <h4>Bonjour ! Prêt à commencer</h4>
                    <h6 className="font-weight-light">Veuillez vous connecter pour continuer.</h6>                    
                    <Form>
                      <div className="pt-3">
                        <div className="form-group">
                          <Field type="email" name="login" className="form-control form-control-lg" placeholder="Email" />
                          <ErrorMessage className="text-danger" name="login" />
                        </div>
                        <div className="form-group">
                          <Field type="password" name="password" className="form-control form-control-lg" placeholder="Mot de passe" />
                          <ErrorMessage className="text-danger" name="password" />
                        </div>
                        <div className="mt-3">
                          <IasButtonSubmit isSubmitting={isSubmitting} texte="Connexion" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </Formik>
  )
}

export default LoginPage