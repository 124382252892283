import { Field, Form, Formik } from "formik"
import PropTypes from "prop-types"
import { Fragment, useState } from "react"
import { useDispatch } from "react-redux"
import InputGroup from "../../../../component/Form/common/InputGroup"
import ToggleSwitch from "../../../../component/Form/common/ToggleSwitch"
import DisponibiliteFields from "../common/DisponibiliteFields"

const LocationFormFacture = ({addLineHandler}) => {

  const dispatch = useDispatch()
  const [produitSelected, setProduit] = useState({prixunitaire: 0})
  const [isDisponible, setIsDisponible] = useState(false)

  return <Fragment>
    <Formik
      initialValues={{ prix: produitSelected.prixunitaire, remise: 0, produit: {}, quantite: 1, descriptif: "", periode: 1, unite: "", isdisponible: false, disponibilite: "", periodeDispo: ""}}
      validate={(values) => {
        const errors = {}
        console.log(values)
        if(values.prix <= 0){errors.prix = "Prix incorrect"}
        if(values.descriptif === ""){errors.descriptif = "Descriptif de la mission manquant"}
        if(values.quantite <= 0){errors.quantite = "Quantité incorrecte"}
        if(values.periode <= 0){errors.periode = "Période incorrecte"}

        return errors
      }}
      onSubmit={(values, actions) => {
        addLineHandler({
          id: values.produit.id,
          reference: "N/D",
          description: values.descriptif,
          quantite: values.quantite,
          pu: values.prix,
          periode: values.periode,
          unite: values.unite,
        })
        actions.resetForm({values: ''})
      }}
    >
      {({errors}) => (
      <Form>
        <div className="row">
          <div className="col-lg-8">
            <div className="form-group">
              <label htmlFor="descriptif">Descriptif de la mission</label>
              <Field as="textarea" className="form-control" name="descriptif" rows="3" placeholder="Saisir des informations de location"/>
              {errors.descriptif && <span className="text-danger text-small">{errors.descriptif}</span>}
            </div>
          </div>
          <div className="col-lg-4">
            <Field handleToggle={setIsDisponible} component={ToggleSwitch} name="isdisponible" label=""/>
            <label style={{display:"inline-block", paddingLeft: "0.5rem"}}>Activer la disponibilité</label> 
            <br/>
            { isDisponible && <DisponibiliteFields />}
          </div>

          <div className="col-lg-3">
            <div className="form-group">
              <InputGroup appendText="FCFA" prependText="Prix">
                <Field name="prix" className="form-control" as="input" type="number" />
              </InputGroup>  
              {errors.prix && <span className="text-danger text-small">{errors.prix}</span>}
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group">
              <InputGroup appendText="%" prependText="Remise">
                <Field name="remise" className="form-control" as="input" type="number" />
              </InputGroup>
            </div>
          </div>
          
          <div className="col-lg-2">
            <div className="form-group">
              <InputGroup prependText="Quantité">
                <Field name="quantite" className="form-control" as="input" type="number" />
              </InputGroup>
              {errors.quantite && <span className="text-danger text-small">{errors.quantite}</span>}
            </div>
          </div>
          
          <div className="col-lg-2">
            <div className="form-group">
              <InputGroup prependText="Période">
                <Field name="periode" className="form-control" as="input" type="number" />
              </InputGroup>
              {errors.periode && <span className="text-danger text-small">{errors.periode}</span>}
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group">
              <Field className="form-control form-control-sm" as="select" name="unite">
                <option>Jours</option>
                <option>Semaine</option>
                <option>Mois</option>
              </Field>
            </div>
          </div>

          <div className="col-lg-1">
            <button className="btn btn-primary mr-2" type="submit">Ajouter</button>
          </div>
        </div>        
      </Form>)}
    </Formik>
  </Fragment>
}

LocationFormFacture.propTypes = {
  addLineHandler: PropTypes.func.isRequired
}

export default LocationFormFacture