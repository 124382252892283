import { Formik, Form, Field } from "formik"
import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import ChauffeurSelect from "../../../component/Form/common/ChauffeurSelect"
import DateInput from "../../../component/Form/common/DateInput"
import IasButtonSubmit from "../../../component/Form/common/IasButtonSubmit"
import Loading from "../../../component/Loading"
import NoInternet from "../../../component/NoInternet"
import StatutDescription, { VEHICULE_ACTIF, VEHICULE_AU_GARAGE, VEHICULE_ENDOMAGE, VEHICULE_EN_MISSION, VEHICULE_VENDU } from "../../../component/StatutDescription"
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "../../../reducer/notificationRx"
import { getGenreVehiculeListe } from "../actions"

const VehiculeForm = ({vehicule}) => {
  const dispatch = useDispatch()

  const { isLoading, isError, data } = useQuery(['vehicules','genres'], getGenreVehiculeListe, {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération des genres de véhicule'})}
  })

  if(isError){
    return <NoInternet />
  }

  if(isLoading) {
    return <Loading />
  }

  return (
  <Formik
    initialValues={{...vehicule}} //dateachat: new Date(vehicule.dateachat), visite: new Date(vehicule.visite), assurance: new Date(vehicule.assurance)
    
    onSubmit={(values, actions) => {
      setTimeout(()=>{
        actions.setSubmitting(false)
        dispatch({type: NOTIFICATION_SUCCESS, payload:"Modification effectuée avec succès"})
      }, 5000)
    }}
    validate={(values) => {
      const errors = {}
      if(values.immatriculation === ""){
        errors.immatriculation = "Immatriculation requise"
      }else if(!/^([0-9]{1,4})([A-Z]{2})([0-2]{2})$/g.test(values.immatriculation)){
        errors.immatriculation = "Immatriculation invalide"
      }

      if(values.genreId === 0){errors.genreId = "Sélectionner le genre du véhicule"}
      if(values.chauffeurId === 0){errors.chauffeurId = "Sélectionner un chauffeur"}
      if(values.dateachat === ""){errors.dateachat = "Sélectionner la date d'achat du véhicule"}
      if(values.coutachat === 0){errors.coutachat = "Saisir le coût du véhcule"}
      if(values.cartegrise === ""){errors.cartegrise = "Saisir la carte grise du véhcule"}
      if(values.marque === ""){errors.marque = "Saisir la carte grise du véhcule"}
      if(values.typecommercial === ""){errors.typecommercial = "Saisir le type commercial ou modèle du véhcule"}
      if(values.couleur === ""){errors.couleur = "Saisir la couleur du véhcule"}
      if(values.energie === ""){errors.energie = "Sélectionner le type d'énergie du véhcule"}
      if(values.nbreplace === "" || 0){errors.nbreplace = "Saisir le nombre de place du véhcule"}
      if(values.energie === ""){errors.energie = "Saisir l'énergie du véhcule"}
      if(values.puissancefiscale === ""){errors.puissancefiscale = "Saisir la puissance fiscale du véhcule"}
      return errors
    }}
  >
    {({isSubmitting, errors, values}) => (
    <Form>
      <div className="row">
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="immatriculation">Immatriculation</label>
            <Field as="input" type="text" name="immatriculation" className="form-control form-control-sm" />
            {errors.immatriculation && <span className="text-danger text-small">{errors.immatriculation}</span>}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="genre_id">Genre</label>
            <Field as="select" name="genreId" className="form-control form-control-sm" defaultValue={vehicule.genre_id}>
              {data.data.map((item, k) => <option key={k} value={item.id}>{item.libelle}</option>)}
            </Field>
            {errors.genreId && <span className="text-danger text-small">{errors.genreId}</span>}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="cartegrise">N° Carte grise</label>
            <Field as="input" type="text" name="cartegrise" className="form-control form-control-sm" />
            {errors.cartegrise && <span className="text-danger text-small">{errors.cartegrise}</span>}
          </div>
        </div>
        
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="marque">Marque</label>
            <Field as="input" type="text" name="marque" className="form-control form-control-sm" />
            {errors.marque && <span className="text-danger text-small">{errors.marque}</span>}
          </div>
        </div>        
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="typecommercial">Type commercial / Modèle</label>
            <Field as="input" type="text" name="typecommercial" className="form-control form-control-sm" />
            {errors.typecommercial && <span className="text-danger text-small">{errors.typecommercial}</span>}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="couleur">Couleur</label>
            <Field as="input" type="text" name="couleur" className="form-control form-control-sm" />
            {errors.couleur && <span className="text-danger text-small">{errors.couleur}</span>}
          </div>
        </div>
        
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="energie">Energie</label>
            <Field as="input" type="text" name="energie" className="form-control form-control-sm" />
            {errors.energie && <span className="text-danger text-small">{errors.energie}</span>}
          </div>
        </div>        
        <div className="col-lg-2">
          <div className="form-group">
            <label htmlFor="puissancefiscale">Puissance fiscale</label>
            <Field as="input" type="number" name="puissancefiscale" className="form-control form-control-sm" />
            {errors.puissancefiscale && <span className="text-danger text-small">{errors.puissancefiscale}</span>}
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group">
            <label htmlFor="nbreplace">Nombre de place</label>
            <Field as="input" type="number" name="nbreplace" className="form-control form-control-sm" />
            {errors.nbreplace && <span className="text-danger text-small">{errors.nbreplace}</span>}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="chauffeur_id">Chauffeur</label>
            <Field component={ChauffeurSelect} name="chauffeurId" className="form-control form-control-sm" />
            {errors.chauffeurId && <span className="text-danger text-small">{errors.chauffeurId}</span>}
          </div>
        </div>

        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="visite">Fin visite technique</label>
            <Field component={DateInput} name="visite" className="form-control form-control-sm" />
            {errors.visite && <span className="text-danger text-small">{errors.visite}</span>}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="assurance">Fin d'assurance</label>
            <Field component={DateInput} name="assurance" className="form-control form-control-sm" />
            {errors.assurance && <span className="text-danger text-small">{errors.assurance}</span>}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="status">Statut du véhicule</label>
            <Field as="select" name="status" className="form-control form-control-sm" defaultValue={vehicule.status}>
              <option value={VEHICULE_ACTIF}><StatutDescription statut={VEHICULE_ACTIF} /></option>
              <option value={VEHICULE_EN_MISSION}><StatutDescription statut={VEHICULE_EN_MISSION} /></option>
              <option value={VEHICULE_AU_GARAGE}><StatutDescription statut={VEHICULE_AU_GARAGE} /></option>
              <option value={VEHICULE_ENDOMAGE}><StatutDescription statut={VEHICULE_ENDOMAGE} /></option>
              <option value={VEHICULE_VENDU}><StatutDescription statut={VEHICULE_VENDU} /></option>
            </Field>
            {errors.status && <span className="text-danger text-small">{errors.status}</span>}
          </div>
        </div>
        
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="dateachat">Date d'achat</label>
            <Field component={DateInput} name="dateachat" className="form-control form-control-sm" />
            {errors.dateachat && <span className="text-danger text-small">{errors.dateachat}</span>}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="coutachat">Coût d'achat</label>
            <Field as="input" type="number" name="coutachat" className="form-control form-control-sm" />
            {errors.coutachat && <span className="text-danger text-small">{errors.coutachat}</span>}
          </div>
        </div>
      </div>
      
      <div className="modal-footer">
        <IasButtonSubmit texte="Enregistrer" isSubmitting={isSubmitting}/>
      </div>
    </Form>)}
  </Formik>)
}
export default VehiculeForm