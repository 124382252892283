import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { Fragment, useState } from 'react'
import { useQuery } from "react-query"
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import NoInternet from '../../../component/NoInternet'
import Pagination from '../../../component/Pagination'
import SkeletonLoader from '../../../component/SkeletonLoader'
import { NOTIFICATION_ERROR } from '../../../reducer/notificationRx'
import IasMenu from '../../../routes/web'
import {getListePersonnel} from '../action'


const PersonnelListePage = () => {
  
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const { isLoading, isError, data } = useQuery(['personnel',page], ()=> getListePersonnel(page), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération du personnel'})}
  })

  if(isError){
    return <NoInternet />
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Personnels</h3>
          <hr/>
          <div className="table-responsive">
            <table className="table table-hover table-sm expandable-table">
              <thead>
                <tr className="table-info">
                  <th>Matricule</th>
                  <th>Nom et prénoms</th>
                  <th>Date d'embauche</th>
                  <th>N° pièce d'identité</th>
                  <th>Service</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? 
                <tr><td colSpan={5}><SkeletonLoader /></td></tr>
                : 
                <Fragment>{data.data.data.map((item, k) => <PersonnelLine item={item} key={k} />)}</Fragment> }
              </tbody>
            </table>
            {data && <Pagination handleSelectPage={setPage} items={data.data.links} />}
          </div>
        </div>
      </div>
    </div>
  )
}

const PersonnelLine = ({item}) => {
  const navigate = useNavigate()
  return (
    <tr onClick={() => navigate(IasMenu.rh.personnel.details.replace(':IdPersonne',item.matricule))}>
      <td style={{lineHeight: "0"}}> {item.matricule} </td>
      <td>{item.nom} {item.prenoms}</td>
      <td>{format(parseISO(item.dateembauche), "dd/MM/yyyy")}</td>
      <td>{item.pieceidentite}</td>
      <td>{item.service.libelle}</td>
    </tr>)
}

export default PersonnelListePage