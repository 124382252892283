import React from 'react';
import { Route, Routes } from 'react-router';
import IasMenu from '../routes/web';
import ClientListePage from '../pages/Partenaire/ClientListePage';
import ClientVisionPage from '../pages/Partenaire/ClientVisionPage';
import Dashboard from '../pages/Dashboard';
import NouvelleFacturePage from '../pages/Factures/Nouveau/NouvelleFacturePage';
import ListeFacturePage from '../pages/Factures/Liste/ListeFacturePage';
import DetailsFacturePage from '../pages/Factures/Details/DetailsFacturePage';
import NouvelleMissionPage from '../pages/Missions/Nouveau/NouvelleMissionPage';
import PersonnelListePage from '../pages/RH/Personnel/PersonnelListePage';
import PersonnelDetailsPage from '../pages/RH/Personnel/PersonnelDetailsPage';
import ListeProduitPage from '../pages/Produits/ListeProduitPage';
import DetailsProductPage from '../pages/Produits/DetailsProductPage';
import ListeVehiculePage from '../pages/Vehicule/ListeVehiculePage';
import DetailsVehiculePage from '../pages/Vehicule/DetailsVehiculePage';
import MissionListePage from '../pages/Missions/MissionListePage';
import { MISSION_EN_COURS } from '../component/StatutDescription';
import ChauffeurListePage from '../pages/RH/Chauffeur/ChauffeurListePage';
import ListeInterventionPage from '../pages/Intervention/ListeIntervention';
import NouveauVehiculePage from '../pages/Vehicule/NouveauVehiculePage';

export default function PageRouter(){

  return (
    <Routes>
      {/*Dasboard*/}
      <Route path={IasMenu.dashboard.base} exact element={<Dashboard />} />
      <Route path={IasMenu.dashboard.accueil} exact element={<Dashboard />} />

      {/*Missions*/}
      <Route path={IasMenu.missions.nouvelle} exact element={<NouvelleMissionPage />} />
      <Route path={IasMenu.missions.encours} exact element={<MissionListePage etat={MISSION_EN_COURS} />} />
      <Route path={IasMenu.missions.liste.vl} exact element={<MissionListePage />} />

      {/*Véhicule*/}
      <Route path={IasMenu.vehicules.nouveau} exact element={<NouveauVehiculePage />} />
      <Route path={IasMenu.vehicules.disponible} exact element={<ListeVehiculePage disponible />} />
      <Route path={IasMenu.vehicules.indisponible} exact element={<ListeVehiculePage />} />
      <Route path={IasMenu.vehicules.details} exact element={<DetailsVehiculePage />} />
      <Route path={IasMenu.vehicules.reparations} exact element={<ListeInterventionPage />} />

      {/*Factures*/}
      <Route path={IasMenu.factures.fromPartner} exact element={<NouvelleFacturePage />} />
      <Route path={IasMenu.factures.proforma} exact element={<NouvelleFacturePage />} />
      <Route path={IasMenu.factures.toutes} exact element={<ListeFacturePage />} />
      <Route path={IasMenu.factures.details} exact element={<DetailsFacturePage />} />
      
      {/*Clients*/}
      <Route path={IasMenu.partenaires.clients} exact element={<ClientListePage />} />
      <Route path={IasMenu.partenaires.details} exact element={<ClientVisionPage />} />

      {/*Personnel */}
      <Route path={IasMenu.rh.personnel.liste} exact element={<PersonnelListePage />} />
      <Route path={IasMenu.rh.personnel.details} exact element={<PersonnelDetailsPage />} />
      <Route path={IasMenu.rh.chauffeur} exact element={<ChauffeurListePage />} />
      
      {/*Produit */}
      <Route path={IasMenu.stock.iventaire} exact element={<ListeProduitPage />} />
      <Route path={IasMenu.stock.details} exact element={<DetailsProductPage />} />
        
    </Routes>
  )
}