import axios from "axios";
import IasMenu from "../routes/web";

const showLoadingBar = (show) => {
  const display = show ? 'block' : 'none'
  let loadingBar = document.querySelector("#loading-bar-request")
  if(loadingBar !== null){
    loadingBar.style.display = display
  }
}

//const token = localStorage.getItem("NVL_TK");

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    showLoadingBar(true)
    if(config.method === "post" || "put"){      

      if(config.data instanceof FormData){
        config.data.append("idUtilisateur", 1)
      }else{
        config.data = {...config.data, idUtilisateur: 1}
      }
    }

    const access_token = localStorage.getItem('access_token') || null
    if (access_token) {
      config.headers['Authorization'] = 'Bearer ' + access_token;
    }

    config.headers['Content-Type'] = config.headers['Content-Type'] === undefined ? 'application/json' : config.headers['Content-Type'];
    config.headers['Accept'] = 'application/json'
    config.baseURL = process.env.REACT_APP_API_URL ?? 'http://localhost/ias/public/api'
  
    return config;
  },
  error => {
    showLoadingBar(false)
    Promise.reject(error)
});

//Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    console.log("request success response")
    showLoadingBar(false)
    return response
  },
  (error) => {
    console.log("request error response")

    const originalRequest = error.config;

    if (error.response.status === 401) {
      console.log("#12 redirection to login")
      document.location.href = IasMenu.auth.login;
      return Promise.reject(error);
    }

    console.log(originalRequest)
    if (error.response.status === 403 && originalRequest.url !== IasMenu.auth.refresh) {
      originalRequest._retry = true;
      const refresh_token = localStorage.getItem('refresh_token') || null
      console.log("#2 blocage car le access token expiré")
      return axios.post('/auth/token/refresh', {
        "token": refresh_token
      })
      .then(response => {
        if (response.status === 201 || response.status === 200) {
          localStorage.setItem("access_token", response.data.access);
          localStorage.setItem("refresh_token", response.data.refresh);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;
          return axios(originalRequest);
        }
      })
      .catch(error => {
        console.log("#3 refresh token expiré, redirection vers page de connexion")
        document.location.href = IasMenu.auth.login;
      })
    }    
    
  return Promise.reject(error);
});


const WebClient = axios
export default WebClient;