import { Fragment } from "react"
import { useNavigate } from "react-router"
import IasMenu from "../../../routes/web"

const VehiculeSearchBox = ({showNouveau}) => {

  const navigate = useNavigate()

  return <Fragment>
    <button className="btn btn-primary" onClick={() => navigate(IasMenu.vehicules.nouveau)}>Nouveau véhicule</button>
  </Fragment>
}

export default VehiculeSearchBox