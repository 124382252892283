import {Formik, Form, Field} from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import DateInput from '../../component/Form/common/DateInput'
import IasButtonSubmit from '../../component/Form/common/IasButtonSubmit'
import ToggleSwitch from '../../component/Form/common/ToggleSwitch'
import TypeInterventionSelect from '../../component/Form/common/TypeInterventionSelect'
import VehiculeSelect from '../../component/Form/common/VehiculeSelect'
import { VEHICULE_ACTIF, VEHICULE_AU_GARAGE, VEHICULE_ENDOMAGE } from '../../component/StatutDescription'
import { NOTIFICATION_SUCCESS } from '../../reducer/notificationRx'

const InterventionForm = ({intervention}) => {

  const dispatch = useDispatch()
  const [vehicule, setVehicule] = useState({})
  const [typeIntervention, setTypeIntervention] = useState({})

  return (
    <Formik
      initialValues={{...intervention}}
      validate={(values) => {
        const errors = {}
        if(values.vehiculeId === 0) {errors.vehiculeId = "Sélection du véhicule obligatoire"}
        if(values.typeInterventionId === 0) {errors.typeInterventionId = "Type de réparation obligatoire"}
        if(values.cout === 0) {errors.cout = "Coût de la réparation obligatoire"}
        if(values.details === "") {errors.reference = "Détails de la réparation obligatoire"}
        return errors
      }}
      onSubmit={(values, actions) => {
        setTimeout(()=>{
          actions.setSubmitting(false);
          dispatch({type: NOTIFICATION_SUCCESS, payload: "Produit enregistré avec succès"})
        }, 10000);
      }}
      >
        {({isSubmitting, errors, values}) => (
        <Form>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="vehiculeId">Véhicule</label>
                <Field name="vehiculeId" className="form-control form-control-sm" component={VehiculeSelect}  onSelectVehicule={(vehicule, form)=>{
                  setVehicule(vehicule)
                  form.setFieldValue("vehiculeId", vehicule.id)
                }} />
                {errors.vehiculeId && <span className="text-danger text-small">{errors.vehiculeId}</span>}
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col-lg-6">              
              <div className="form-group">   
                <Field component={ToggleSwitch} name="changeStatut" label=""/>
                <label style={{display:"inline-block", paddingLeft: "0.5rem"}}>Changer statut</label> 
              </div>  
            </div>
            {values.changeStatut && <div className="col-lg-6">                     
              <div className="form-group">
                <label htmlFor="status">Etat du véhicule</label>
                <Field as="select" name="status" className="form-control form-control-sm">
                  <option value={VEHICULE_ACTIF}>Actif</option>
                  <option value={VEHICULE_ENDOMAGE}>Endommagé</option>
                  <option value={VEHICULE_AU_GARAGE}>Au garage</option>
                </Field>
              </div>
            </div>}
          </div>

          <div className='row'>              
            <div className='col-lg-6'>              
              <div className="form-group">
                <label htmlFor="dateDebut">Date début</label>
                <Field name="dateDebut" className="form-control form-control-sm" component={DateInput}/>
                {errors.dateDebut && <span className="text-danger text-small">{errors.dateDebut}</span>}
              </div>
            </div>

            <div className='col-lg-6'>              
              <div className="form-group">
                <label htmlFor="dateFin">Date fin</label>
                <Field name="dateFin" className="form-control form-control-sm" component={DateInput}/>
                {errors.dateFin && <span className="text-danger text-small">{errors.dateFin}</span>}
              </div>
            </div>
          </div>
          
          <div className='row'>
            <div className='col-lg-6'>
              <div className="form-group">
                <label htmlFor="typeInterventionId">Type Intervention</label>
                <Field name="typeInterventionId" className="form-control form-control-sm" component={TypeInterventionSelect}  onSelectTypeIntervention={(typeIntervention, form)=>{
                  setTypeIntervention(typeIntervention)
                  form.setFieldValue("typeInterventionId", typeIntervention.id)
                }} />
                {errors.TypeInterventionId && <span className="text-danger text-small">{errors.TypeInterventionId}</span>}
              </div>
            </div>
            <div className='col-lg-6'>
              <div className="form-group">
                <label htmlFor="details">Coût</label>
                <Field as="input" type="number" className="form-control form-control-sm" name="cout" />
              </div>              
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <div className="form-group">
                <label htmlFor="details">Détails / Commentaire</label>
                <Field as="textarea" className="form-control form-control-sm" rows={4} placeholder="" name="details"/>
                {errors.vehiculeId && <span className="text-danger text-small">{errors.details}</span>}
              </div>
            </div>
          </div>

          <IasButtonSubmit texte="Enregistrer" isSubmitting={isSubmitting} />
        </Form>)}
    </Formik>
  )
}

export default InterventionForm