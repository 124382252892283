import WebClient from "../../utils/WebClient"

export const VL = "VL"
export const PL = "PL"

export function getListeMissions(page, search){
  return WebClient.get(`/missions/liste?page=${page}&search=${search}`)
}

export function addNewMission(data){
  return WebClient.post(`/missions/add`, data)
}