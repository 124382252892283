import { Fragment, useState } from "react"
import PropTypes from 'prop-types'

const ModalContent = ({lauchnButtonTitle, title, primaryButtonText, withCloseButton, withLargeModal, component}) => {

  const [_style, setStyle] = useState({ display: "none" })
  const [show, setShow] = useState(false)

  const displayModal = () =>{
    setShow(true)
    setStyle({display: " block ", paddingRight: "17px"})
  }

  const hideModal = () =>{
    setShow(false)
    setStyle({display: " none ", paddingRight: "17px"})
  }

  return (
    <Fragment>
    <button type="button" className="btn btn-info mb-4" data-bs-toggle="modal" onClick={displayModal} data-bs-target="#ias-modal" data-whatever="IAS">{lauchnButtonTitle}</button>
    
    <div className={`modal fade ${show ? 'show' : 'hide'}`} id="ias-modal" tabIndex="-1" aria-labelledby="ModalLabel" style={_style} aria-hidden="true">
      <div className={`modal-dialog ${withLargeModal ? 'modal-lg' : ''}`} role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="ModalLabel">{title}</h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" onClick={hideModal}>×</span>
            </button>
          </div>
          <div className="modal-body">{component}</div>
          {(primaryButtonText !== "" || withCloseButton) &&
          <div className="modal-footer">
            {primaryButtonText !== "" && <button type="button" className="btn btn-success">{primaryButtonText}</button>}
            {withCloseButton && <button type="button" onClick={hideModal} className="btn btn-light" data-dismiss="modal">Fermer</button>}
          </div>}
        </div>
      </div>
    </div>
    </Fragment>
  )
}

ModalContent.defaultProps = {
  withCloseButton: true,
  withLargeModal: false,
  primaryButtonText: "",
}
ModalContent.propTypes = {
  lauchnButtonTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
}

export default ModalContent