import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import DefaultLoadingPage from "../../component/DefaultLoadingPage"
import { USER_LOGIN } from "../../reducer/authRx"
import IasMenu from "../../routes/web"
import WebClient from "../../utils/WebClient"

const RequireAuth = ({children}) => {

  const user = useSelector((state) => state.user) 
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const checkToken = (async function(){
    const refreshJwt = localStorage.getItem('refresh_token') || null 
    
    if(refreshJwt){
      console.log("#5 - refresh token trouvé")
      return await WebClient.post("/auth/token/refresh",{token: refreshJwt})
        .then((response) => {
          localStorage.setItem("access_token", response.data.access)
          localStorage.setItem("refresh_token", response.data.refresh)        
          dispatch({type: USER_LOGIN, payload: response.data.user})
          return EnumLoginState.ON_CHECK_SUCCESS
        })
        .catch((error) => {
          console.log("#10 - refresh token expiré")
          return EnumLoginState.ON_CHECK_FAILED
        })
    }else{
      console.log("#11 refresh non trouvé dans le localstorage")
      return EnumLoginState.ON_CHECK_FAILED
    }  
  })

  if(user.isConnected === false){ 
    console.log("#4 l'utilisateur n'est pas connecté depuis le store")    
    checkToken().then((result => {
      console.log(result,"#10 le refresh est down")    
      if(result === EnumLoginState.ON_CHECK_FAILED){
        //document.location.href = IasMenu.auth.login;
        console.log(result,"#11 check failed") 
        navigate(IasMenu.auth.login, {replace: false})
      }
    })).catch(error => {
      console.log("error",error)
    });
    return <DefaultLoadingPage />
  }else{
    return children
  }  
}

class EnumLoginState{
  static ON_CHECK_TOKEN = 0
  static ON_CHECK_FAILED = 1
  static ON_CHECK_SUCCESS = 2
}


export default RequireAuth