const IasMenu = {
  auth: {
    login: "/auth/login",
    refresh: "/auth/token/refresh"
  },
  dashboard: {
    base : "/",
    accueil: "/home"
  },
  missions: {
    nouvelle: "/missions/nouvelle",
    encours : "/missions/en-cours",
    liste: {
      vl: "/missions/vehicule-leger",
      pl: "/missions/poids-lourd"
    },
    details: "/missions/:reference/details"
  },
  vehicules: {
    nouveau: "/vehicules/nouveau",
    details: "/vehicules/:immatriculation/details",
    disponible : "/vehicules/disponibles",
    indisponible : "/vehicules/indisponibles",
    reparations: "/vehicules/reparations",
  },
  partenaires: {
    clients: "/partenaires/clients/",
    fournisseurs: "/partenaires/fournisseurs/",
    details:"/partenaire/:partnerId/details",
  },
  factures: {
    proforma: "/factures/nouvelle/proforma",
    fromPartner: "/factures/:partnerId/nouvelle",
    toutes: "/factures/",
    details: "/factures/:reference/details",
  },
  tresorerie: {
    comptes: "/tresorerie",
  },
  stock: {
    iventaire: "/stock/inventaire",
    details: "/stock/:reference/details",
    synthese: "/stock/synthese",
    mouvements : "/stock/mouvements",
    famille : "/stock/famille",
    sortie : "/stock/sorties",
  },
  rh: {
    personnel: {
      liste: "/rh/personnel",
      details: "/rh/personnel/:IdPersonne/details"
    },
    chauffeur: "/rh/chauffeurs",
    old_staff: "/rh/personnel/anciens"
  },
  config: {
    utilisateurs: "/config/utilisateurs",
  }
}

export default IasMenu