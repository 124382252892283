import { Fragment } from "react"
import ContentLoader from "react-content-loader"
import Loading from "../Loading"

const SkeletonLoader = ({speed, width, height, background, foreground, template}) => (
  <ContentLoader
    speed={speed}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor={background}
    foregroundColor={foreground}
    /*{...props}*/
  >
    <circle cx="10" cy="20" r="8" /> 
    <rect x="25" y="15" rx="5" ry="5" width="420" height="10" /> 
    <circle cx="10" cy="50" r="8" /> 
    <rect x="25" y="45" rx="5" ry="5" width="420" height="10" /> 
    <circle cx="10" cy="80" r="8" /> 
    <rect x="25" y="75" rx="5" ry="5" width="420" height="10" /> 
    <circle cx="10" cy="110" r="8" /> 
    <rect x="25" y="105" rx="5" ry="5" width="420" height="10" /> 
  </ContentLoader>
)

SkeletonLoader.defaultProps = {
  speed: 2,
  width: 200,
  height: 150,
  background: "#f3f3f3",
  foreground: "#ecebeb",
  template: <Loading />
}

export default SkeletonLoader