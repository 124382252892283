import { Field, Form, Formik } from "formik";
import { useState, Fragment} from "react";
import PropTypes from 'prop-types'
import InputGroup from "../../../../component/Form/common/InputGroup";
import ProductSelect from "../../../../component/Form/common/ProductSelect";
import { useDispatch } from "react-redux";
import ToggleSwitch from "../../../../component/Form/common/ToggleSwitch";
import DisponibiliteFields from "../common/DisponibiliteFields";

const PieceFormFacture = ({addLineHandler}) => {
  const dispatch = useDispatch()
  const [produitSelected, setProduit] = useState({prixunitaire: 0})
  const [isDisponible, setIsDisponible] = useState(false)
  const defaultValues = {prix: produitSelected?.prixunitaire, remise: 0, produit: {}, quantite: 1, complement: "", isdisponible: false, disponibilite: "", periodeDispo: ""}

  return <Fragment>
    <Formik
      initialValues={defaultValues}
      validate={(values) => {
        const errors = {}
        console.log(values)
        if(values.prix <= 0){errors.prix = "Prix incorrect"}
        if(values.produit.reference === undefined){errors.produit = "Produit non selectionné"}
        if(values.quantite <= 0){errors.quantite = "Quantité incorrecte"}

        return errors
      }}
      onSubmit={(values, actions) => {
        let dispo = <Fragment>{values.isdisponible && <><br/>{values.disponibilite} ({values.periodeDispo})</>}</Fragment>
        let complement = <Fragment>{values.complement !== "" && <><br/>{values.complement}</>}</Fragment>
        let description = <Fragment>{values.produit.libelle} {complement} {dispo} </Fragment> 
        console.log(values)
        addLineHandler({
          id: values.produit.id,
          reference: values.produit.reference,
          description: description,
          quantite: values.quantite,
          pu: values.prix,
          isDisponible: values.isdisponible,
        })
        actions.resetForm({values: {...defaultValues}})
      }}
    >
      {({errors}) => (
      <Form>
        <div className="row">
          <div className="col-lg-8">
            <div className="form-group">
              <label htmlFor="objet">Produits</label>
              <Field name="produit" className="form-control" component={ProductSelect}  onSelectProduct={(produit, form)=>{
                  setProduit(produit)
                  form.setFieldValue("prix", produit.prixunitaire)
                }} />         
              {errors.produit && <span className="text-danger text-small">{errors.produit}</span>}
              <div className="mt-2">
                <div className="badge badge-outline-info badge-pill mr-2">Prix : {produitSelected.prixunitaire} F</div>
                <div className="badge badge-outline-info badge-pill mr-2">Disponible : {produitSelected.isdisponible === 1 ? "Oui" : "Non"}</div>
                <div className="badge badge-outline-info badge-pill mr-2">En stock : {produitSelected.stock}</div>
                <div className="badge badge-outline-info badge-pill">Famille : {produitSelected.famille?.libelle}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Field handleToggle={setIsDisponible} component={ToggleSwitch} name="isdisponible" label=""/>
            <label style={{display:"inline-block", paddingLeft: "0.5rem"}}>Activer la disponibilité</label> 
            <br/>
            { isDisponible && <DisponibiliteFields />}
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <label htmlFor="complement">Complément de produit</label>
              <Field as="textarea" className="form-control" name="complement" rows="4" placeholder="Saisir des infos complémentaires sur le produit"/>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <InputGroup appendText="FCFA" prependText="Prix">
                <Field name="prix" className="form-control" as="input" type="number" />
              </InputGroup>  
              {errors.prix && <span className="text-danger text-small">{errors.prix}</span>}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <InputGroup appendText="%" prependText="Remise">
                <Field name="remise" className="form-control" as="input" type="number" />
              </InputGroup>
            </div>
          </div>
          
          <div className="col-lg-2">
            <div className="form-group">
              <InputGroup prependText="Quantité">
                <Field name="quantite" className="form-control" as="input" type="number" />
              </InputGroup>
              {errors.quantite && <span className="text-danger text-small">{errors.quantite}</span>}
            </div>
          </div>

          <div className="offset-lg-1 col-lg-2">
            <button className="btn btn-primary mr-2" type="submit">Ajouter</button>
          </div>
        </div>        
      </Form>)}
    </Formik>
  </Fragment>
}

export default PieceFormFacture

PieceFormFacture.propTypes = {
  addLineHandler: PropTypes.func.isRequired
}