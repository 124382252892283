import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DateInput from "../../../component/Form/common/DateInput";
import IasButtonSubmit from "../../../component/Form/common/IasButtonSubmit";
import PartnerSelect from "../../../component/Form/common/PartnerSelect";
import ToggleSwitch from "../../../component/Form/common/ToggleSwitch";
import FactureModeSelector from "../../../component/Form/Facture/select";
import { NOTIFICATION_SUCCESS } from "../../../reducer/notificationRx";
import { FACTURE_LOCATION, FACTURE_PIECE } from "../action";
import PartenaireInfo from "./common/PartenaireInfo";
import FactureTabView from "./FactureTabView";
import LocationFormFacture from "./Forms/LocationFormFacture";
import PieceFormFacture from "./Forms/PieceFormFacture";

const NouvelleFacturePage = () => {
  let params = useParams();
  const dispatch = useDispatch()

  const addNewLine = (line) => {
    let oldData = [...lines];
    oldData.push(line)
    setLines(oldData)
  }
  const [lines, setLines] = useState([])
  const [partner, setPartner] = useState({})
  const [mode, setMode] = useState(FACTURE_PIECE)
  const [isExonere, setIsExonere] = useState(false)

  return (
  <div className="col-lg-12">    
    <div className="card px-2">
      <div className="card-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <div className="form-group">
                <FactureModeSelector behavior={setMode} />
              </div>
            </div>
            <div className="offset-lg-1 col-lg-4">
              <h3 className="text-right my-5">Facture Proforma</h3>
            </div>
            <div className="offset-lg-1 col-lg-4">
              {params.partnerId !== undefined ? <PartenaireInfo id={params.partnerId} /> :
              <div className="form-group">
                <label>Client</label>
                <PartnerSelect name="partnerId" className="form-control" onSelectPartner={(partner, form)=>{setPartner(partner)}} />
              </div>} 
            </div>
          </div>
        </div>
        <hr/>
        <div className="container-fluid">
          {mode === FACTURE_PIECE && <PieceFormFacture addLineHandler={addNewLine} />}
          {mode === FACTURE_LOCATION && <LocationFormFacture addLineHandler={addNewLine} />}
        </div>

        <hr/>
        <Formik
          initialValues={{ datefacture: new Date(), objet:"", isexonere: false, validite: "", conditions:"", delai:"", partnerId: 0}}
          validate={(values) => {
            const errors = {}
            if(values.objet === ""){errors.objet = "Objet de la facture vide"}
            if(values.validite === ""){errors.validite = "Validité de la facture vide"}
            if(values.conditions === ""){errors.conditions = "Conditions de paiement de la facture vide"}
            if(values.delai === ""){errors.delai = "Délai de paiment de la facture vide"}
            return errors
          }}
          onSubmit={(values, actions) => {
            if(params.partnerId !== undefined){
              values.partnerId = params.partnerId
            }else{
              values.partnerId = partner.id
            }
            values.mode = mode
            values.lines = lines
            setTimeout(() => {
              console.log(JSON.stringify(values));
              actions.setSubmitting(false);
              dispatch({type: NOTIFICATION_SUCCESS, payload: "Facture enregistée avec succès. Référence : 00000010"})
            }, 5000);
          }}
        >
          {({isSubmitting, errors}) => (
          <Form>
            <div className="row">
              <div className="col-lg-2">
                <div className="form-group">
                  <label htmlFor="">Date de facture</label>
                  <Field name="datefacture" className="form-control" component={DateInput}/>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="objet">Objet de la facture</label>
                  <Field as="input" name="objet" className="form-control form-control-sm" placeholder="Objet de la facture" />
                  {errors.objet && <span className="text-danger text-small">{errors.objet}</span>}
                </div>
              </div>
            </div>
            <div className="container-fluid mt-5 d-flex justify-content-center w-100">
              <FactureTabView 
                lines={lines} 
                mode={mode}
                handleRemoveItem={() => {}}
                handleUpdateQuantity={(index, quantite) => {
                  lines.at(index).quantite = quantite <= 1 ? 1 : quantite
                  setLines([...lines])
                }}
                handleUpdatePeriode={(index, periode) => {
                  lines.at(index).periode = periode <= 1 ? 1 : periode
                  setLines([...lines])
                }}
                />
            </div>
            <hr/>
            <div className="container-fluid w-100 row">
              <div className="offset-lg-8 col-lg-2">Montant HT : </div>
              <div className="col-lg-2 text-right"><b>{Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(lines.reduce((somme, item) => somme + (item.quantite * item.pu * (item.periode ?? 1)),0))}</b></div>
            </div>
            <div className="container-fluid w-100 row">
              <div className="offset-lg-8 col-lg-2">Exonération : </div>
              <div className="col-lg-2 text-right">
                <small style={{display:"inline-block", paddingRight: "0.5rem"}}>{isExonere? "Exonéré de TVA" : "Normal"}</small> 
                <Field handleToggle={setIsExonere} component={ToggleSwitch} name="isexonere" label=""/></div>
            </div>
            <div className="container-fluid w-100 row">
              <div className="offset-lg-8 col-lg-2">TVA 18% : </div>
              <div className="col-lg-2 text-right"><b>{Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format(0.18 * lines.reduce((somme, item) => somme + (item.quantite * item.pu * (item.periode ?? 1)),0))}</b></div>
            </div>
            <div className="container-fluid w-100 row">
              <div className="offset-lg-8 col-lg-2">Montant TTC : </div>
              <div className="col-lg-2 text-right"><b>{Intl.NumberFormat("fr-FR", {style: "currency", currency: "XOF"}).format( (isExonere ? 1 : 1.18) * lines.reduce((somme, item) => somme + (item.quantite * item.pu * (item.periode ?? 1)),0))}</b></div>
            </div>
            <hr/>
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="delai">Délais de livraison</label>
                  <Field as="input" name="delai" className="form-control form-control-sm" placeholder="Délai de livraison" />
                  {errors.delai && <span className="text-danger text-small">{errors.delai}</span>}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="delais">Conditions de paiement</label>
                  <Field as="input" name="conditions" className="form-control form-control-sm" placeholder="Conditions de paiement" />
                  {errors.conditions && <span className="text-danger text-small">{errors.conditions}</span>}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="validite">Validité de l'offre</label>
                  <Field as="input" name="validite" className="form-control form-control-sm" placeholder="Validité de l'offre" />
                  {errors.validite && <span className="text-danger text-small">{errors.validite}</span>}
                </div>
              </div>
            </div>
            <hr />
            <div className="container-fluid w-100">
              <IasButtonSubmit className="btn btn-success float-right mt-4 ml-2" texte="Enregistrer la commande" isSubmitting={isSubmitting}/>
            </div>
          </Form>)}
        </Formik>
      </div>
    </div>
  </div>)
}

export default NouvelleFacturePage