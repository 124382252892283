import { Fragment } from "react"
import { useDispatch } from "react-redux"
import PropTypes from 'prop-types'
import { useQuery } from "react-query";
import { NOTIFICATION_ERROR } from "../../../../reducer/notificationRx";
import { getPartenaireById } from "../../../Partenaire/action";
import Loading from "../../../../component/Loading";

const PartenaireInfo = ({id}) => {
  
  const dispatch = useDispatch()
  const { isLoading, isError, data } = useQuery(['partner', id], ()=> getPartenaireById(id), {
    retry: false,
    onError: (error) => {dispatch({type: NOTIFICATION_ERROR, payload: 'Erreur de récupération des informations du partenaire'})}
  })

  if(isLoading) {return <Loading />}

  return (
    <Fragment>
      <p className="mt-3 mb-2"><b>{data.data.raisonsociale}</b></p>
      <p>
        {data.data.telephone},
        <br/>{data.data.comptecontribuable}
      </p>
    </Fragment>
  )
}

PartenaireInfo.propTypes = {
  id : PropTypes.number.isRequired
}

export default PartenaireInfo