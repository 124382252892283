import { Fragment, useState } from "react"
import PropsType from 'prop-types'

const DropdownButton = ({title, menus}) => {

  const [openMenu, setOpenMenu] = useState(false)

  let _style = {margin: "0px"}
  let _show = ""
  if(openMenu){
    _style = {
      ..._style,
      position: "absolute", 
      inset: "0px auto auto -18px", 
      transform: "translate(112.8px, 44px)"
    }
    _show = "show"
  }

  return (
    <div className="btn-group" onBlur={() => {setOpenMenu(false)}}>
      <button type="button" className="btn btn-secondary">{title}</button>
      <button type="button" onClick={() => setOpenMenu(!openMenu) } className={`btn btn-secondary dropdown-toggle dropdown-toggle-split ${_show}`} data-popper-placement>
        <span className="sr-only">{title}</span>
      </button>
      <div className={`dropdown-menu ${_show}`} style={_style} data-popper-placement="bottom-start">
        {menus.map((element, k) => (
           <Fragment>
            {element.isSepareted && <div className="dropdown-divider"></div>}
            {element.item}
           </Fragment> 
        ))}
      </div>
    </div>
  )
}


DropdownButton.defaultProps = {
  title: "Actions",
  menus: [
    {item: <a href="#1">test lien</a>, isSepareted: false},
    {item: <a href="#1">test lien</a>, isSepareted: false},
    {item: <a href="#2">test lien2</a>, isSepareted: true},
  ]
}
DropdownButton.propsType = {
  title: PropsType.any.isRequired,
  menus: PropsType.arrayOf(PropsType.shape({
    item: PropsType.any.isRequired,
    isSepareted: PropsType.bool,
  }))
}

export default DropdownButton