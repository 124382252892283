import AsyncSelect from 'react-select/async';
import { searchProduct } from "./behavior"

const ProductSelect = ({ field, form, onSelectProduct, ...props}) => {

  const handleChange = (value) => {}

  return (
    <AsyncSelect
      onChange={option => {
        form.setFieldValue(field.name, option.value)
          if(onSelectProduct !== undefined ){onSelectProduct(option.value, form)}
      }}
      defaultOptions
      cacheOptions
      onInputChange={handleChange}
      loadOptions={searchProduct}
      />)
}

export default ProductSelect