import PropTypes from 'prop-types'

// Crédit : https://materialdesignicons.com/

const IasIcons = ({size, color, className}) => {
  return <i style={{color: color, fontSize: size, position:"relative", top: "4px" }} className={`menu-icon mdi mdi-${className}`}></i>
}

IasIcons.defaultProps = {
  size: "20px",
  color: "green"
}

IasIcons.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string.isRequired,
}
export default IasIcons

export const IasValidIcon = "checkbox-marked-circle"
export const IasWarningIcon = "alert-outline"