import React, { useState } from 'react';
import IasMenu from '../routes/web';

const withMenu = Component => (props) => {
  const [item, setItem] = useState(null)

  const menus = [
    {
      item: "Tableau de bord", 
      icon: "mdi mdi-home", 
      link: IasMenu.dashboard.base, 
      children: [

      ]
    },
    {
      item: "Missions", 
      icon: "mdi mdi-airplane-takeoff", 
      link: "#", 
      children: [ 
        {item: "Nouvelle", link: IasMenu.missions.nouvelle},
        {item: "En cours", link: IasMenu.missions.encours},
        {item: "Véhicules légers", link: IasMenu.missions.liste.vl}, 
        //{item: "Poids lourds", link: IasMenu.missions.liste.pl}
      ]
    },
    {
      item: "Véhicules", 
      icon: "mdi mdi-car", 
      link: "#", 
      children: [
        {item: "Disponibles", link: IasMenu.vehicules.disponible}, 
        {item: "Indisponibles", link: IasMenu.vehicules.indisponible}, 
        {item: "Reparations", link: IasMenu.vehicules.reparations}
      ]
    },
    {
      item: "Partenaires", 
      icon: "mdi mdi-briefcase", 
      link: "#", 
      children: [
        {item: "Clients", link: IasMenu.partenaires.clients}, 
        {item: "Fournisseurs", link: IasMenu.partenaires.fournisseurs}]
    },
    {
      item: "Factures", 
      icon: "mdi mdi-file-document", 
      link: "#", 
      children: [
        {item: "Nouvelle", link: IasMenu.factures.proforma}, 
        {item: "Factures", link: IasMenu.factures.toutes}, 
        /*{item: "Fournisseurs", link:"/factures/fournisseurs"}*/
      ]
    },
    {
      item: "Trésorerie", 
      icon: "mdi mdi-cash-multiple", 
      link: IasMenu.tresorerie.comptes, 
      children: []
    },
    {
      item: "Stock", 
      icon: "mdi mdi-package-variant", 
      link: "#", 
      children: [
        {item: "Inventaire", link: IasMenu.stock.iventaire}, 
        {item: "Synthèse", link: IasMenu.stock.synthese},
        {item: "Mouvements", link: IasMenu.stock.mouvements}, 
        {item: "Famille", link: IasMenu.stock.famille}, 
        {item: "Sortie stock", link: IasMenu.stock.sortie}
      ]
    },
    {
      item: "Personnel", 
      icon: "mdi mdi-account-card-details", 
      link: "#", 
      children: [
        {item: "Employés", link: IasMenu.rh.personnel.liste}, 
        {item: "Chauffeurs", link:IasMenu.rh.chauffeur}, 
        {item: "Anciens employés", link:IasMenu.rh.old_staff}, 
        /*{item: "Salaire", link:"/personnel/salaire"}*/
      ]
    },
    {
      item: "Administration", 
      icon: "mdi mdi-settings", 
      link: "#", 
      children: [
        {item: "Utilisateurs", link: IasMenu.config.utilisateurs},
      ]
    },
  ]

  return <Component data={menus} selected={item} change={setItem}/>
}

export default withMenu